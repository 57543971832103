.modal-dialog button, .login-home button{
    outline: none !important;
    box-shadow: none !important;
}  

.stcoksherpa-divbdr {
    border: 3px solid #aec906;
    border-radius: 50px;
}
.dviewteam .vission-div {
    background: url(../images/team1.jpg) no-repeat scroll 0 0;
    background-position: bottom;
    background-size: cover;
    padding: 65px 75px;
    border: 2px solid #e78235;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    position: relative;
    z-index: 9; 
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    margin: 0 -15px 30px -15px;   
}

.dviewteam .vission-div h3 {
    color: #e78235;
    font-size: 35px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 10px;
    text-align: left;
    padding-left: 0;
}
.dviewteam .vission-div p {
    color: #fff;
    font-size: 20px;
    line-height: 30px; 
    font-weight: bold;
    text-align: left;
}
.dviewteam .vission-div:after {
    background: url(../images/moon-1.png) no-repeat scroll 0 0;
    position: absolute;
    content: '';
    right: 25px;
    width: 132px;
    height: 117px;
    top: 50%;
    z-index: -1;
    left: auto;
    margin-top: -85px;
}

.contact-us-details:after {
    background: url(../images/moon-1.png) no-repeat scroll 0 0;
    position: absolute;
    content: '';
    right: 0;
    width: 132px;
    height: 117px;
    top: 20px;
    z-index: 1;
    left: auto;
}

.activetab {
    background: #231f20;
}
.activetab a{
   color: #f58220;
}

.activemenu a {
    color:  #fff;
}

.cmpstexpand i {
 cursor: pointer;
}

.userdtls a:hover{
   color: #f58220;
}
.bymkt .searchinrdiv, .bytop .searchinrdiv, .industryfreebox .searchrespdiv {
    cursor: pointer;
}
.pagination li a {
    cursor: pointer;
}
#chart1 .aihead {
    text-align: center;
}
#chart1 .aihead p {
    margin: 0;
    color: #fff;
    font-weight: 600;
    text-decoration: underline;
}

.ChartIcon{
    width: 50px; 
    height: 50px; 
    background: #f58220;
    padding: 5px; 
    border-radius: 5px; 
    cursor: pointer; 
}

.ChartHed {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
}
 
.loader {
    position: absolute;
    left: 50%;
    top: 25%;
    z-index: 1;
    width: 120px;
    height: 120px;
    margin: -76px 0 0 -76px;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  /* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
   
  .loadertxt
  {
    margin-top: 230px;
  }

  .rpaylink a {
    text-decoration: none;
    cursor: pointer; 
    font-size: 14px;
    padding-right: 10px;
    color: #252525;
    font-weight: bold;
   
  }
  .rpaylink a:hover { 

    color: #fff;
}

.rpaylink span {
    width: 8px;
    height: 8px;
    display: inline-flex;
    background-color: #000;
    border-radius: 6px;
    vertical-align: middle;
}
 
@media only screen and (min-width: 320px) and (max-width: 991px) {
    nav.navbar {
        background: #58c7e1 !important;
    }
    /* .navbar li:last-child {
       display: none;
    } */
    #abt {
        margin-top: 68px;
    }
    .moonstar img{
      width: 30%;
    }
}

@media only screen and (min-width: 320px) and (max-width: 575px) {
    .mat-header-cell, .mat-footer-cell, .mat-cell
  {
    min-width: 50px !important;
    border: 0.3px solid #f58220;
  }
    .dview2, .dview1, .dview3 {
        display: none;
    }  
    .productsbg {
        background: url(../images/our_product_bg.png) no-repeat scroll 0 0;
        background-size: cover;
        background-position: 100% 100%;
    }
    .faqbg {
        background: url(../images/our_product_bg.png) no-repeat scroll 0 0;
        background-size: cover;
        background-position: 100% 100%;
    }
    #myModaldash5 img {
        width: 100%;
    }
    #myModaldash5 .btns a {
     width: 120px;
     font-size: 16px;
    }
    #myModaldash5 .modal-header .close { 
        padding: 5px 10px 5px 10px;
    }
    #myModaldash5 button {
        outline: none;
        box-shadow: none;
    }  
    .contact-us-details:after {
        display: none;
    }
    .fhmenumar {
        margin-top: 68px;
    } 
    .dareport {
        background: #3c3b3a;
 
    }
    .comparetxt {
        margin-bottom: 1px;
    }

    .failurediv{
        margin-top: 25%;
    }
    .failurediv img {
        width: 100%; 
    }
   
}
 
@media only screen and (min-width: 576px) and (max-width: 600px) {
    .home-details {        
        margin-top: -100px;
    }
    .stcoksherpa-div {
        padding: 10px 20px 30px 20px; 
      }
      .fredtimg {
        margin-top: 0; 
      }
      .prodemail1 .form-control{
        margin: 5px 0;
      } 
      .teamdtls {
          padding:0 10px;
      }
      .productsbg {
        background: url(../images/our_product_bg.png) no-repeat scroll 0 0;
        background-size: cover;
        background-position: 100% 100%;
    } 
    .faqimgdiv {
        margin-left: -15px;
        margin-right: -15px;
    }
    #faq img {
        width: 100%; 
    }
    .subscription-view a {
        font-size: 11px;
    }
    .dviewteam .vission-div{
        padding: 20px 157px 20px 20px;
        margin: 0 -15px 10px -15px;
    } 
    .team-content p {
        margin: 0 5px 0 0 !important;
    }
    .dviewteam .vission-div p {
        font-size: 17px;
    }
    .content-stt h3 {
        font-size: 16px;
    }
    .content-stt p{
        font-size: 16px;
        margin-bottom: 0 !important;
    }
    .dviewteam .rmgn {
        margin-top: 0 !important;
    }
    .faq-content-st {
        width: 75%;
    }
    button.owl-prev i {
        transform: rotate(-90deg);  
    }
    .footer-image-contact img {
      width: 60% !important;
    }
    .contact-us-details {
        background: #3b3a39 url(../images/mountain.jpg) no-repeat scroll 0 0;
        background-size: contain;
        height: 500px;
        position: relative; 
    }
    .pad-div p {
        color: #fff;
        margin-bottom: 20px;
        font-weight: 500;
        font-size: 16px;
    }
    .pad-div.add {
        padding-top: 50px;
    }
    .pad-div {
        padding: 0 25px;
    }
    .address-fields-div {
        display: flex;
        justify-content: space-between;
        width: 80%;
    }
    ul.address-fields-div li {
        display: inline-block;
        width: auto;
        padding: 0 0px 0 0;
        position: relative;
    }
    .bstyle {
        border-left: 1px solid #f58220;
    }
    .ratios {
        margin-top: -350px;
    }
    .logindtls {        
        margin-top: -20px;
    }

    .fhmenumar { 
        margin-top: 68px;
    }
    .moonstar img{
        width: 20%;
        top: 186px;
    }
    .nav-pills .nav-link { 
        padding: .55rem;
    }
    .nav-pills li a {
        width: 80px;
        font-size: 11px !important;
    }
    .font-weight-bold {
        font-weight: 600 !important;
    }
    .mr-sm-3, .mx-sm-3 {
        margin-right: 0.50rem!important;
    }
    .comparetxt {
        margin-left: -8px;
    }
    .freedb .btns a {
        cursor: pointer;
    }
    .freedb .portfoliotxt span {
        font-size: 12px;
    }
    .freedb .portfoliotxt i {
        font-size: 12px;
    }
    .dtlstockdtls p {
        font-size: 12px;
    }
    .compare .btns a {
        cursor: pointer;
    }
    .dviewfaq h2 {
        position: absolute;
        top : 0; 
        width: 95%; 
      }
      
    #myModaldash5 .modal-body {
        border-radius: 50px;
    }
    #myModaldash5 .modal-header .close {
        padding: 3px 10px 5px 10px;
    }
    #myModaldash5 img {
        width: 100%;
    }
    .failurediv{
        margin-top: 25%;
    } 
}

@media only screen and (min-width: 601px) and (max-width: 625px) {
    .productsbg {
        background: url(../images/our_product_bg.png) no-repeat scroll 0 0;
        background-size: cover;
        background-position: 100% 100%;
    }
}

@media only screen and (min-width: 626px) and (max-width: 767px) {
.productsbg {
    background: url(../images/our_product_bg1.png) no-repeat scroll 0 0;
    background-size: contain;
    background-position: right;
} 
}

@media only screen and (min-width: 601px) and (max-width: 767px) {
    .home-details {        
        margin-top: -120px;
    }
    .stcoksherpa-div {
        padding: 10px 20px 30px 20px; 
      }
      .fredtimg {
        margin-top: 0; 
      }
    .prodemail1 .form-control{
        margin: 5px 0;
      }  
      .teamdtls {
        padding:0 40px;
    }
    
    .faqimgdiv {
        margin-left: -15px;
        margin-right: -15px;
    }
    #faq img {
        width: 100%; 
    } 

    .dviewteam .vission-div{
        padding: 20px 157px 20px 20px;
        margin: 0 -15px 10px -15px;
    } 
    .team-content p {
        margin: 0 5px 0 0 !important;
    }
    .dviewteam .vission-div p {
        font-size: 17px;
    }
    .content-stt h3 {
        font-size: 18px;
    }
    .content-stt p{
        font-size: 16px;
        margin-bottom: 0 !important;
    }
    .dviewteam .rmgn {
        margin-top: 0 !important;
    }
    .faq-content-st {
        width: 70%;
        min-height: 160px;
    }
    button.owl-prev i {
        transform: rotate(-90deg);  
    }
    .footer-image-contact img {
      width: 60% !important;
    }
    .contact-us-details {
        background: #3b3a39 url(../images/mountain.jpg) no-repeat scroll 0 0;
        background-size: contain;
        height: 500px;
        position: relative; 
    }
    .pad-div p {
        color: #fff;
        margin-bottom: 20px;
        font-weight: 500;
        font-size: 16px;
    }
    .pad-div.add {
        padding-top: 50px;
    }
    .pad-div {
        padding: 0 25px;
    }
    .address-fields-div {
        display: flex;
        justify-content: space-between;
        width: 80%;
    }
    ul.address-fields-div li {
        display: inline-block;
        width: auto;
        padding: 0 0px 0 0;
        position: relative;
    }
    .bstyle {
        border-left: 1px solid #f58220;
    }
    .ratios {
        margin-top: -350px;
    }
    .logindtls {        
        margin-top: -40px;
    }

    .fhmenumar { 
        margin-top: 68px;
    }
    .moonstar img{
        width: 15%;
        top: 186px;
    }
    .nav-pills .nav-link { 
        padding: .55rem;
    }
    .nav-pills li a {
        width: 80px;
        font-size: 11px !important;
    }
    .font-weight-bold {
        font-weight: 600 !important;
    }
    .mr-sm-3, .mx-sm-3 {
        margin-right: 0.50rem!important;
    }
    .comparetxt {
        margin-left: -8px;
    }
    .freedb .portfoliotxt span {
        font-size: 12px;
    }
    .freedb .portfoliotxt i {
        font-size: 12px;
    }
    .dtlstockdtls p {
        font-size: 12px;
    }
    .freedb .btns a {
        cursor: pointer;
    }
    .compare .btns a {
        cursor: pointer;
    }
    .dviewfaq h2 {
        position: absolute;
        top : 0; 
        width: 95%; 
      }
      #myModaldash5 img {
        width: 100%;
    } 
    #myModaldash5 .modal-body {
        border-radius: 50px;
    }
    #myModaldash5 .modal-header .close {
        padding: 3px 10px 5px 10px;
    }
    .failurediv{
        margin-top: 15%;
    } 
} 

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .home-details {        
        margin-top: -150px;
    }
    .stcoksherpa-div {
        padding: 10px 30px 30px 30px; 
      }
      .fredtimg {
        margin-top: 0; 
      }
    .prodemail1 .form-control{
        margin: 5px 0;
      }  
      .teamdtls {
        padding:0 50px;
    }
    .productsbg {
        background: url(../images/our_product_bg1.png) no-repeat scroll 0 0;
        background-size: contain;
        background-position: right;
    } 
    .faqimgdiv {
        margin-left: -15px;
        margin-right: -15px;
    }
    #faq img {
        width: 100%; 
    }
    .dviewfooter .footer-image-contact img {
        width: 85%;
    }
    .dviewteam .vission-div{
        padding: 30px 157px 30px 20px;
        margin: 0 -15px 10px -15px;
    } 
    .faq-content-st {
        width: 95%;
        min-height: 160px;
    }
    button.owl-prev i {
        transform: rotate(-90deg);  
    }
    .contact-us-details {
        background: #3b3a39 url(../images/mountain.jpg) no-repeat scroll 0 0;
        background-size: contain;
        height: 500px;
        position: relative; 
    }
    .pad-div p {
        color: #fff;
        margin-bottom: 20px;
        font-weight: 500;
        font-size: 16px;
    }
    .pad-div.add {
        padding-top: 50px;
    }
    .pad-div {
        padding: 0 25px;
    }
    .address-fields-div {
        display: flex;
        justify-content: space-between;
        width: 60%;
    }
    ul.address-fields-div li {
        display: inline-block;
        width: auto;
        padding: 0 0px 0 0;
        position: relative;
    }
    .bstyle {
        border-left: 1px solid #f58220;
    }
    .ratios {
        margin-top: -350px;
    }
    .logindtls {        
        margin-top: -120px;
    }

    .fhmenumar { 
        margin-top: 68px;
    }
    .moonstar img{
        width: 15%;
        top: 186px;
    }
    .nav-pills .nav-link { 
        padding: .10rem;
    }
    .nav-pills li a {
        width: 50px;
        font-size: 8px !important;
    }
    .font-weight-bold {
        font-weight: 500 !important;
    }
    .mr-sm-3, .mx-sm-3 {
        margin-right: 0.5rem!important;
    }

    .divbdr { 
        margin-top: -75px;
        border-left: 2px solid #3c3b3a; 
      } 
    .comparetxt a {
        font-size: 14px !important;
        padding-right: 0;
    }
    .dareport p span {
        font-size: 14px;
    }
    .freedb .portfoliotxt span {
        font-size: 14px;
    }
    .freedb .portfoliotxt i
    {
        font-size: 14px;
    }
    .dtlstockdtls p {
        font-size: 12px;
    }
    .cmpstexpand i {
        z-index: 6;
    }

    .stockhead a {
        font-size: 14px;
    }
    .searchbox input {
        font-size: 16px;
    }
    .userdtls a {
        font-size: 14px;
    }   
    .bdrl {
        padding-left: 7px !important;
    }
    .divbdr1 {  
        border-left: 2px solid #3c3b3a; 
    }  
    .comparetxt {
        margin-left: -12px;
    }
    #aianalysis1 .rmpadding  .col-lg-3 {
        padding: 0;
    }
    .compareres .cmpstname p { 
        padding: 5px 2px;
    }
    .freedb .btns a {
        cursor: pointer;
    }
    .compare .btns a {
        cursor: pointer;
    }
    .dviewfaq h2 {
        position: absolute;
        top : 0; 
        width: 98%; 
      }
      #myModaldash5 img {
        width: 100%;
    }
    #myModaldash5 .modal-body {
        border-radius: 50px;
    }
    #myModaldash5 .modal-header .close {
        padding: 3px 10px 5px 10px;
    }
    .failurediv{
        margin-top: 15%;
    }
    .rpaypage {
        margin-bottom: 70px;
   }
   .navbar-collapse{
    margin: 0 !important;
}

} 

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .navbar-nav {
     background: none;
    }
    .navbar li{
        width: 16.66%;
    }
    .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
    width: 100%;
    }
    .fixed .navbar {
    background: #68c6e2 !important;
    }
    .navbar li {
        border-right: 1px solid #a5a2a1;
    }
    .navbar-nav {
        background: transparent;
        border: 1px solid #a5a2a1;
    }
    .navbar-collapse{
        margin: 0 !important;
    }
    .navbar-collapse ul li {
        border-top: none;
        border-bottom: none;
        padding: 10px 0;
    } 
    .nav-link {
        padding: 0;
    }
    .bg-light {
        background: transparent !important;
       
    } 
    .navbar li:last-child {
        border-right: 0;
    }
    nav.navbar {
        border-bottom: none;
    }
    .nav-item a.active {
        color: #fff !important;
    }

    .home-details {        
        margin-top: -180px;
    }
    .stcoksherpa-div {
        padding: 10px 50px 30px 50px; 
      }
    .padd30{
    padding-right: 30px;
    }
    .padd95{
    padding-right: 95px;
    }
    .padd75{
    padding-right: 75px;
    }
    .fredtimg {
        margin-top: 0; 
      }
      .subscription-view a {
        height: 59px;
    }
    .prodemail1 .form-control{
        margin: 5px 0;
      }
      .subscription-view a { 
        line-height: 45px;
        font-size: 16px;
    }  
    .teamdtls {
        padding:0 50px;
    }
    .productsbg {
        background: url(../images/our_product_bg1.png) no-repeat scroll 0 0;
        background-size: contain;
        background-position: right;
    } 
    .faqimgdiv {
        margin-left: -15px;
        margin-right: -15px;
    }
    #faq img {
        width: 100%; 
    }
    .faq-content-st {
        width: 98%;
        padding: 9px 5px;
        min-height: 160px;
     }
     .dviewfaq .row {
       margin-top: -100px;
     }
     .dviewfooter .footer-image-contact img {
        width: 55%;
    }
    button.owl-prev i {
        transform: rotate(-90deg);  
    }
    .contact-us-details {
        background: #3b3a39 url(../images/mountain.jpg) no-repeat scroll 0 0;
        background-size: contain;
        height: 600px;
        position: relative; 
    }
    .pad-div p {
        color: #fff;
        margin-bottom: 20px;
        font-weight: 500;
        font-size: 16px;
    }
    .pad-div.add {
        padding-top: 50px;
    }
    .pad-div {
        padding: 0 25px;
    }
    .address-fields-div {
        display: flex;
        justify-content: space-between;
        width: 50%;
    }
    ul.address-fields-div li {
        display: inline-block;
        width: auto;
        padding: 0 0px 0 0;
        position: relative;
    }
    .comn .oktodetail .oktodetailblk p
    {
        font-size: 13px;
        color: #000;
        line-height: 15px;
        font-family: avantgargotitcteedem;
        padding: 3px 10px 0;
        margin: 0;
    }

    .bstyle {
        border-left: 1px solid #f58220;
    }
    .ratios {
        margin-top: -350px;
    }
    .logindtls {        
        margin-top: -160px;
    }
    .freehdr .navbar li{
        width: 33.33% !important;
    }
    .freehdr .navbar {
        background: #68c6e2 !important;
    }
    .fhmenumar { 
        margin-top: 36px;
    }
    .moonstar img{
        width: 10%;
        top: 146px;
      }
      .nav-pills .nav-link { 
        padding: .10rem;
    }
    .nav-pills li a {
        width: 55px;
        font-size: 9px !important;
    }
    .font-weight-bold {
        font-weight: 500 !important;
    }
    .mr-sm-3, .mx-sm-3 {
        margin-right: 0.5rem!important;
    }

    .divbdr { 
        margin-top: -75px;
        border-left: 2px solid #3c3b3a; 
      } 
    .comparetxt a {
        font-size: 14px !important;
        padding-right: 0;
    }
    .dareport p span {
        font-size: 14px;
    }
    .freedb .portfoliotxt span {
        font-size: 14px;
    }
    .freedb .portfoliotxt i
    {
        font-size: 14px;
    }
    .dtlstockdtls p {
        font-size: 12px;
    }
    .cmpstexpand i {
        z-index: 6;
    }

    .stockhead a {
        font-size: 14px;
    }
    .searchbox input {
        font-size: 16px;
    }
    .userdtls a {
        font-size: 14px;
    } 
    .freedb .comparetxt span {
        font-size: 17px;
    }
    .comn .srheadblk p {
        line-height: 17px;
        font-size: 8px;
    }
    .comn .srdetail .srdetailblk .srdetailnum {
        font-size: 16px;
    }
    .comn .srdetail .srdetailblk .srdetailtxt {
        font-size: 11px;
        line-height: 15px;
    }
    .comn .aidetail .aidetailblk p {
        font-size: 14px;
    }
    .comn .aadetail .aadetailblk p {
        font-size: 12px;
    }
    .comn .oktoaadetail .oktoaadetailblk p {
        font-size: 12px;
    }
    .sanalysis .sahead {
        font-size: 12px;
    }
    .sacontent p {
        font-size: 12px;
    }
    .bdrl {
        padding-left: 7px !important;
    }
    #overview1 .aiblock .aitxt p {
        font-size: 10px;
    } 
    #aianalysis1 .aiblock .aitxt p {
        font-size: 10px;
    }
    #aianalysis1 .aiblock .aitxt p span {
        font-size: 9px;
    }
    #aianalysis1 .headstyle {
        font-size: 14px;
    }
    #aianalysis1 .oktoblock .oktotxt p {
        font-size: 10px;
    }
    .divbdr1 {  
        border-left: 2px solid #3c3b3a; 
      } 
      .gviewtxt p {
          font-size: 12px;
      }
      .compareres .cmpsthead p {
        font-size: 12px;
      }
      .compareres .cmpsrdetail .cmpsrdetailblk .cmpsrdetailnum {
          font-size: 16px;
      }
      .compareres .cmpsrdetail .cmpsrdetailblk .cmpsrdetailtxt {
          font-size: 10px;
      }
      .compareres .cmpoktohead p {
          font-size: 10px;
      }
      .compareres .cmpstname p {
          font-size: 12px;
          padding: 5px 2px;
      }
      .compareres .cmpstval p {
          font-size: 14px;
      }
      .compareres .cmpstoktoval p {
        font-size: 14px;
      }
      .comparetxt {
        margin-left: -8px;
    }
    #aianalysis1 .rmpadding  .col-lg-3 {
        padding: 0;
    }
    .freedb .btns a {
        cursor: pointer;
    }
    .compare .btns a {
        cursor: pointer;
    }
    .dviewfaq h2 {
        position: absolute;
        top : 0; 
        width: 98%; 
      }
      #myModaldash5 img {
        width: 100%;
    }    
    #myModaldash5 .modal-body {
        border-radius: 50px;
    }
    #myModaldash5 .modal-header .close {
        padding: 3px 10px 5px 10px;
    }
    .failurediv{
        margin-top: 10%;
    }
    .rpaypage {
        margin-bottom: 70px;
   }

  } 
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .navbar-nav {
        background: none;
       }
       .navbar li{
        width: 16.66%;
       }
       .navbar-expand-lg .navbar-nav {
       -ms-flex-direction: row;
       flex-direction: row;
       width: 100%;
       }
       .fixed .navbar {
       background: #68c6e2 !important;
       }
       .navbar li {
        border-right: 1px solid #a5a2a1;
    }
    .navbar-nav {
        background: transparent;
        border: 1px solid #a5a2a1;
    }
    .navbar-collapse{
        margin: 0 !important;
    }
    .navbar-collapse ul li {
        border-top: none;
        border-bottom: none;
        padding: 10px 0;
    }
    .nav-link {
        padding: 0;
    }
    .bg-light {
        background: transparent !important;
        
    } 
    .navbar li:last-child {
        border-right: 0;
    }
    nav.navbar {
        border-bottom: none;
    }
    .nav-item a.active {
        color: #fff !important;
    }
    .home-details {        
        margin-top: -220px;
    }
    .popup-content {
      padding: 0 60px;
    }
    .stcoksherpa-div {
        padding: 10px 50px 30px 50px; 
      }
    .padd30{
      padding-right: 50px;
    }
    .padd95{
      padding-right: 115px;
    }
    .padd75{
      padding-right: 150px;
    }
    .fredtimg {
      margin-top: 0; 
    }

    .subscription-view a {
        height: 59px;
    }
    .products-details p {
        padding-bottom: 0;
    }
    .prodemail1 .form-control{
      margin: 5px 0;
    }
    .subscription-view a { 
        line-height: 55px;
        font-size: 16px;
    }  
    .teamdtls {
        padding:0 50px;
    }
    .productsbg {
        background: url(../images/our_product_bg1.png) no-repeat scroll 0 0;
        background-size: contain;
        background-position: right;
    } 
    .faqimgdiv {
        margin-left: -15px;
        margin-right: -15px;
    }
    #faq img {
        width: 100%; 
    }
    .faq-stt:after {
        z-index: 1;
    }
    .faq-content-st {
       width: 80%;
       min-height: 160px;
    }
    .dviewfaq .row {
      margin-top: -100px;
    } 

    .contact-us-details {
        background: #3b3a39 url(../images/mountain.jpg) no-repeat scroll 0 0;
        background-size: contain;
        height: 600px;
        position: relative; 
    }
    .pad-div p {
        color: #fff;
        margin-bottom: 20px;
        font-weight: 500;
        font-size: 16px;
    }
    .pad-div.add {
        padding-top: 50px;
    }
    .pad-div {
        padding: 0 75px;
    }
    .address-fields-div {
        display: flex;
        justify-content: space-between;
        width: 50%;
    }
    ul.address-fields-div li {
        display: inline-block;
        width: auto;
        padding: 0 0px 0 0;
        position: relative;
    }
    .bstyle {
        border-left: 1px solid #f58220;
    }
    .dviewfooter .footer-image-contact img {
        width: 55%;
    }
    .ratios {
        margin-top: -350px;
    }
    .logindtls {        
        margin-top: -180px;
    }
    .comn .oktodetail .oktodetailblk p
    {
        font-size: 14px;
        color: #000;
        line-height: 15px;
        font-family: avantgargotitcteedem;
        padding: 3px 20px 0;
        margin: 0;
    }
    .freehdr .navbar li{
        width: 33.33% !important;
    }
    .freehdr .navbar {
        background: #68c6e2 !important;
    }
    .fhmenumar { 
        margin-top: 36px;
    }
    .moonstar img{
        width: 10%;
        top: 146px;
      }
      .nav-pills .nav-link { 
        padding: .35rem;
    }
    .nav-pills li a {
        width: 75px;
        font-size: 12px !important;
    }
    .mr-sm-3, .mx-sm-3 {
        margin-right: 0.5rem!important;
    }

    .divbdr { 
        margin-top: -75px;
        border-left: 2px solid #3c3b3a; 
      } 
    .comparetxt a {
        font-size: 14px !important;
        padding-right: 25px;
    }
    .dareport p span {
        font-size: 14px;
    }
    .freedb .portfoliotxt span {
        font-size: 14px;
    }
    .freedb .portfoliotxt i
    {
        font-size: 14px;
    }
    .dtlstockdtls p {
        font-size: 12px;
    }
    .cmpstexpand i {
        z-index: 6;
    }

    .stockhead a {
        font-size: 14px;
    }
    .searchbox input {
        font-size: 16px;
    }
    .userdtls a {
        font-size: 14px;
    } 
    .freedb .comparetxt span {
        font-size: 17px;
    }
    .comn .srheadblk p {
        line-height: 17px;
        font-size: 10px;
    }
    .comn .srdetail .srdetailblk .srdetailnum {
        font-size: 16px;
    }
    .comn .srdetail .srdetailblk .srdetailtxt {
        font-size: 12px;
        line-height: 15px;
    }
    .comn .aidetail .aidetailblk p {
        font-size: 14px;
    }
    .comn .aadetail .aadetailblk p {
        font-size: 12px;
    }
    .comn .oktoaadetail .oktoaadetailblk p {
        font-size: 12px;
    }
    .sanalysis .sahead {
        font-size: 12px;
    }
    .sacontent p {
        font-size: 12px;
    }
    .bdrl {
        padding-left: 15px !important;
    }
    #overview1 .aiblock .aitxt p {
        font-size: 10px;
    } 
    #aianalysis1 .aiblock .aitxt p {
        font-size: 10px;
    }
    #aianalysis1 .aiblock .aitxt p span {
        font-size: 9px;
    }
    #aianalysis1 .headstyle {
        font-size: 15px;
    }
    #aianalysis1 .oktoblock .oktotxt p {
        font-size: 10px;
    }
    .divbdr1 {  
        border-left: 2px solid #3c3b3a; 
      } 
      .gviewtxt p {
          font-size: 12px;
      }
      .compareres .cmpsthead p {
        font-size: 12px;
      }
      .compareres .cmpsrdetail .cmpsrdetailblk .cmpsrdetailnum {
          font-size: 16px;
      }
      .compareres .cmpsrdetail .cmpsrdetailblk .cmpsrdetailtxt {
          font-size: 10px;
      }
      .compareres .cmpoktohead p {
          font-size: 10px;
      }
      .compareres .cmpstname p {
          font-size: 12px;
          padding: 5px 2px;
      }
      .compareres .cmpstval p {
          font-size: 14px;
      }
      .compareres .cmpstoktoval p {
        font-size: 14px;
      }
      .comparetxt {
        margin-left: 8px;
    }
    .freedb .btns a {
        cursor: pointer;
    }
    .compare .btns a {
        cursor: pointer;
    }
    .searchinrdiv .cntnum {
       width: 2%;
    }
    .searchinrdiv .qstxt {
        width: 98%;
    }
    .schprefix {
        width: 10%;
    }
    .schprefixval {
        width: 90%;
    }
    #myModaldash5 img {
        width: 100%;
    }
    .dviewfaq h2 {
      position: absolute;
      top : 0; 
      width: 98%; 
    }
    .form-control { 
        padding: .75rem;
    }
    .dviewbtn {
      margin-top: 5px;
    }
    .dviewbtn .click_btn {
      height: 52px !important;
      width: 80px !important;
     
    }
    .dviewbtn .btns a {
        font-size: 16px;
    }

    #myModaldash5 .modal-body {
        border-radius: 50px;
    }
    #myModaldash5 .modal-header .close {
        padding: 3px 10px 5px 10px;
    }

    #aianalysis1 .aiblock .aitxt p
    {
        margin-top: 5px;
    }
    .failurediv{
        margin-top: 10%;
    }
    
    .selheight{
        height: 50px !important;
    }

    .rpaypage {
         margin-bottom: 70px;
    }
   
  }


  #financials1 .aihead {
    text-align: center;
}
#financials1 .aihead p {
    margin: 0;
    color: #fff;
    font-weight: 600;
    text-decoration: underline;
}
#deals1 .aihead {
    text-align: center;
}
#deals1 .aihead p {
    margin: 0;
    color: #fff;
    font-weight: 600;
    text-decoration: underline;
}
#profitnloss1 .aihead {
    text-align: center;
}
#profitnloss1 .aihead p {
    margin: 0;
    color: #fff;
    font-weight: 600;
    text-decoration: underline;
}
#shareholding1 .aihead {
    text-align: center;
}
#shareholding1 .aihead p {
    margin: 0;
    color: #fff;
    font-weight: 600;
    text-decoration: underline;
}