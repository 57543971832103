.navigation-clean {
  background: #fff;
  padding-top: .75rem;
  padding-bottom: .75rem;
  /* color: #333; */
  border-radius: 0;
  box-shadow: none;
  border: none;
  margin-bottom: 0;
}

@media (min-width:768px) {
  .navigation-clean {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.navigation-clean .navbar-brand {
  font-weight: bold;
  color: inherit;
}

.navigation-clean .navbar-brand:hover {
  color: #222;
}

.navigation-clean .navbar-toggler {
  border-color: #ddd;
}

.navigation-clean .navbar-toggler:hover, .navigation-clean .navbar-toggler:focus {
  background: none;
}

.navigation-clean .navbar-toggler {
  color: #888;
}

.navigation-clean .navbar-collapse, .navigation-clean .form-inline {
  border-top-color: #ddd;
}

.navigation-clean.navbar-light .navbar-nav .nav-link.active, .navigation-clean.navbar-light .navbar-nav .nav-link.active:focus, .navigation-clean.navbar-light .navbar-nav .nav-link.active:hover {
  /* color: #8f8f8f; */
  box-shadow: none;
  background: none;
  /* pointer-events: none; */
}

.navigation-clean.navbar .navbar-nav .nav-link {
  padding-left: 18px;
  padding-right: 18px;
}

/* .navigation-clean.navbar-light .navbar-nav .nav-link {
  color: #465765;
} */

.navigation-clean.navbar-light .navbar-nav .nav-link:focus, .navigation-clean.navbar-light .navbar-nav .nav-link:hover {
  /* color: #37434d !important; */
  background-color: transparent;
}

.navigation-clean .navbar-nav > li > .dropdown-menu {
  margin-top: -5px;
  box-shadow: none;
  background-color: #fff;
  border-radius: 2px;
}

.navigation-clean .dropdown-menu .dropdown-item:focus, .navigation-clean .dropdown-menu .dropdown-item {
  line-height: 2;
  color: #37434d;
}

.navigation-clean .dropdown-menu .dropdown-item:focus, .navigation-clean .dropdown-menu .dropdown-item:hover {
  background: #eee;
  color: inherit;
}

#nd-nav-split {
  position: relative;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

#nd-header .nav-item {
  padding: 15px 0;
  text-align: center;
  border-top: 1px solid #4e4e4e;
  border-bottom: 1px solid #4e4e4e;
}

#nd-header .navbar-nav {
  background: url("../../../assets/images/dropdown_menu_bg.png") no-repeat scroll 0 0;
  background-size: cover;
  background-position: 50%;
}

@media (min-width: 768px) {
  #nd-header .container-fluid {
    padding: 0;
  }
}

@media (min-width: 768px) {
  #nd-header .navbar-nav li {
    width: 20.00% !important;
    border-top: none;
    border-bottom: none;
    padding: 10px 0;
  }
}

#nd-header .navbar-nav {
  border: 1px solid #a5a2a1;
}

.navbar-nav {
  margin: 0;
}

@media (min-width: 768px) {
  #nd-header .navbar-nav li:not(:last-child) {
    border-right: 1px solid #a5a2a1;
  }
}

@media (min-width: 768px) {
  #nd-header .nav-link {
    padding: 0;
  }
}

#nd-hme-content h1 span {
  font-weight: 700;
}

@media (min-width: 300px) {
  #nd-summit-content .col-4 {
    padding: 0 7px;
  }
}

@media (max-width: 576px) {
  #nd-summit-content .col-4 img {
    width: 50px;
    height: auto;
  }
}

#nd-content-sec .row {
  width: 100%;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .fixed .navbar {
    background: #68c6e2!important;
  }
}

