/*------Landing Page Header -----*/
p,h1,h2,h3,h4,h5,h6,span,a,button{
  font-family: AvantGarGotltcTEE;
  line-height:1.5;
}
.cdk-overlay-container
{
  z-index:1050;
}
.cdk-overlay-pane
{
  max-height: 65%;
}
.mr-auto1, .mx-auto1 {
  margin-left: auto!important;
}
.fa-facebook-square {
  color: #3b5998;
  
  background-size: 55%;
  background-position: 70% 0;
  background-repeat: no-repeat;
}
.lplogin
  {
    color: black;
    padding-left:19%;
  }
nav.navbar {
  background: #58c7e1;
  border-bottom: 1px solid #fff;
  
}
#logo h1 {
  color: #000;
  font-size: 24px;
  border-bottom: 1px solid #000;
  margin-bottom: 0!important;
}
#logo p {
  font-size: 14px;
  text-align: center;
  color: #fff;
  margin-bottom: 5px;
  line-height: 20px;
}
#logo h1 span {
  text-transform: uppercase;
  font-weight: 700;
}
#logo {
  font: 400 24px/32px Roboto,Helvetica Neue,sans-serif;
  letter-spacing: normal;
} 
.navbar-nav li a {
  color: #000 !important;
  font-size: 16px;
  letter-spacing: 2px;
  font-weight: 600; 
}
.navbar-nav li a:hover {
  -webkit-transition: color 1s;  
  transition: color 1s;
  color: #fff; 
}
.authhdr .navbar-toggler
{ 
  border:none;
}
.userdtls {
  background: #231f20;
  padding: 8px 2px;
  text-align: center;
}
.userdtls a{
  color: #f58220;
  font-size: 12px;
  font-weight: 600;
}
.tdec
{
  text-decoration: underline;
} 
.htdec a:hover {
  text-decoration: none;
 }

/*------Landing Page Footer-----*/
.contact-footer {
  background: #56c8e3;
  padding: 10px 0;
}
.footer-image-contact {
  margin-top: -100px;
  margin-bottom: 5px;
}
.footer-right {
  text-align: left;
}
.footer-right h3 {
  font-size: 14px;
  margin-bottom: 10px;
}
.footer-right p {
  font-size: 12px;
  font-weight: 500;
}
.contact-footer h2 {
  font-size: 27px; 
  padding-bottom: 5px;
  margin-bottom: 0;
  display: inline-block;
  display: block;
  margin-left: 0;
  color: #000;
}
.contact-footer .line { 
  padding-bottom: 5px;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  width: fit-content;
}

/*------Landing Page-----*/
.home-details {
  text-align: center;
  padding: 0;
}

.home-details h2 {
  font-size: 48px;
  border-bottom: 1px solid #000;
  margin-bottom: 10px;
  display: inline-block;
  font-weight: 100;
}
.hmdtlsbold
{
  font-weight: bold !important;
  
}
.home-details p {
  color: #000;
}
.click_btn {
  background: url(./assets/images/button.png) no-repeat scroll 0 0;
  background-size: cover;
  color: #000;
  width: 258px;
  height: 50px;
  display: inline-block;
  line-height: 50px;
}
.img-responsive {
  display: block;
  height: auto;
  width: 100%;
}
.home {
  position: absolute;
  content: '';
  left: 0;
  top: 68px;
  background: url(./assets/images/01-full.png) no-repeat scroll 0 0;
  width: 100%;
  height: 776px;
  background-size: contain;
  background-position: center;
}
.footer-bg{padding-bottom:50px;}
.footer-bg #home-slider .owl-item img{width:100% !important;}
.footer-bg {
  background: url(./assets/images/grey.png) no-repeat scroll 0 0;
  background-size: cover;
  padding: 35px 15px 70px;
  width: 100%;
  width: 100%;
  position: relative;
  bottom: 0;
}
.abtstock
{
  padding-bottom: 5px;
  font-size: 16px;
}
.align-center {
  align-items: center;
  display: inline-grid;
  width: 100%;
  text-align: center;
}
.who_text_anchot a {
  color: #fff;
  border: 2px solid #fff;
  padding: 3px 16px;
  font-size: 18px;
  display: inline-block;
  position: relative;
  top: 0;
  font-weight: 500;
}
a:hover
{
  color: #000;
}
.who_text
{
height: 85px;
width: 200px !important;
padding-left: 20px;
}
.whowhatpimg
{
  width: 61%; margin-bottom: 4%;
}
.owl-theme .owl-nav.disabled+.owl-dots {
  margin-top: 10px;
  
}
.owl-dots {
  position: absolute;
  top: -40px;
  margin-top: 0;
  left: 50%;
  margin-left: -5px;
}
.owl-dots button {
  outline: none;
  box-shadow: none;
} 

.bg-primary button {
  outline: none;
  box-shadow: none;
} 

.about-mountain {
  background: #3b3a39 url(./assets/images/mountain.jpg) no-repeat scroll 0 0;
  background-size: contain;
  text-align: center;
  padding: 80px 15px;
  position: relative;
}
.about-mountain:after {
  position: absolute;
  content: '';
  left: 50%;
  background: #aec906;
  width: 3px;
  height: 100%;
  top: 0;
  margin-left: -3px;
}
.popup-content img {
  width: 70%;
  margin-bottom: 6px;
}
p.heit-cls {
  min-height: 160px;
}
.about-mountain p {
  color: #f6f6f6;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 10px;
}
.stcoksherpa-div h3 {
  margin-bottom: 20px;
  font-size: 22px;
  text-decoration: underline;
  text-transform: capitalize;
  color: #dce492;
  }
.stcoksherpa-div p {
  font-size: 17px;
  margin-bottom: 0;
  line-height: 25px;
  color: #dce492;
  }

 
.products-check {
  background: #afca05;
  text-align: center;
}
.products-check p {
  padding: 15px 0;  
  font-size: 16px; 
  }
 /*.scbdr {
  border: 2px solid #a5a2a1; 
}*/
.scbdrlft {
  border-left: 1px solid #a5a2a1;
}
.scbdrrgt {
  border-right: 1px solid #a5a2a1;
}

.border .stock-check ul {
  height: 100%;
}
.products-view-details .stock-check ul {
  position: relative;
}

.stricke-line {
font-size: 16px;
text-align: center;
margin: 0 auto;
display: -webkit-inline-box;
position: relative;
}
.stricke-line:after {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: red;
  top: 50%;
  left: 0;
  content: "";
  margin-top: -1px;
}
 
.subscription-view {
  text-align: center;
  padding-top: 10px;
} 

.subscription-view a {
  margin: 10px 0;
  background: url(./assets/images/button.png) no-repeat scroll 0 0;
  background-size: cover;
  color: #000;
  width: 100%;
  height: 35px;
  display: inline-block;
  line-height: 31px;
  font-size: 14px;
  border-radius: 10px;
  padding: 0 11px;

}

.ourproductsbg {
  background: url(./assets/images/our_product_bg.png) no-repeat scroll 0 0;
  background-size: cover;
  background-position: 100% 100%;
}
.instagram
{
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%); 
    background: -webkit-radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);    

}
.products h2 {
 text-align: left;
 display: block;
 /* margin-left: 20px; */
 padding-top: 25px;
 border-bottom: 1px solid #000;
 padding-bottom: 5px;
 margin-bottom: 10px;
 font-size: 30px;
}
.products h2 span {
  font-weight: 700;
}

.products .teamdtls p {
  
  font-size: 16px;
  font-weight: 400;
  font-weight: 500;
  margin-bottom: 10px!important;
}

.vission-div {
  background: url(./assets/images/our_team.png) no-repeat scroll 0 0;
  background-size: cover;
  /* padding: 6px; */
  
  border: 4px solid #e78235;
  border-radius: 25px;
  margin: 10px 0;
}
.vission-div h3 {
  color: #fff;
  font-size: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 0;
  text-align: left;
  padding-left: 15px;
  line-height:1;
  }
.vission-div p {
  color: #fff;
  
    font-size: 16px;
    font-weight: 400;
  }
.team-image img {
  margin-bottom: 10px;
  max-width: 100%;
  height: 100px;
}
.team-image ul {
  margin: 0;
  padding: 0;
}
.team-image ul.social-links li {
  display: inline-block;
  margin-right: 5px;
  margin-top: 0;
}
.team-image ul.social-links li i {
  color: #444;
  font-size: 25px;
}
.team-content h3 {
  text-align: left;
  font-size: 20px;
  margin-bottom: 5px;
}
.team-content p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 4px;
}

.blog-slider h3.title {
  margin-bottom: 5px;
  text-align: left;
  padding-left: 45px;
  font-size: 16px;
}
.blog-slider h3 span {
  font-weight: 700;
}
.blog-image {
  background: url(./assets/images/blog_ss.jpg) no-repeat scroll 0 0;
  background-size: cover;

  
}
.blog-st-div {
  background: #9bbc2a;
  padding: 15px;
}
.content-stt h3 {
  font-size: 18px;
  margin-bottom: 5px;
  text-transform: uppercase;
  text-align: left;
  font-weight: 400;
}
.content-stt a {
  color: #58585a;
  border: 2px solid #58585a;
  padding: 3px 15px;
  font-size: 20px;
  display: block;
  float: right;
  margin-right: 5px;
  font-weight: 700;
}
.owl-nav {
  position: absolute;
  bottom: 0;
}
.owl-theme .owl-nav {
  margin-top: 10px;
}
.owl-theme .owl-dots, .owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0!important;
  font: inherit;
}
.owl-theme .owl-nav [class*=owl-] {
  color: #fff;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}
.owl-theme .owl-dots .owl-dot span
{
  background:#B1CB1F !important;
}
.owl-carousel .owl-dot, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}
.blog-content button.owl-next i, .blog-content button.owl-prev i {
  color: #58585a;
  font-size: 25px;
}
button.owl-prev i {
  transform: rotate(90deg);
}
button.owl-next i {
  transform: rotate(270deg);
}

.owl-carousel button {
  outline: none;
  box-shadow: none;
} 

.faq-stt {
  padding-bottom: 50px;
}
.faq-stt:after {
  position: absolute;
  content: "";
  bottom: 0;
  background: url(./assets/images/faq_012.png) no-repeat scroll 0 0;
  background-size: cover;
  width: 228px;
  height: 367px;
  left: 0;
  z-index: -1;
  box-sizing: border-box;
  margin-left: 10px;
}
.faq-qt {
  padding-left: 70px;
  
} 

.products p.text-center.att {
  padding: 0 0;
  font-size: 16px; 
  }
.faq-content-tt {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.faq-content-st {
  background: #fff;
  border: 2px solid #e78235;
  padding: 9px 9px;
  min-height: 132px;
  margin-bottom: 20px;
  position: relative;
  align-items: center;
  display: flex;
  width: 48%;
}
.faq-content-st h3 {
  word-break: break-word;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  padding-bottom: 15px;
  text-align: left;
  position: relative;
  
}
.faq-content-st h3 a {
  color: #000;
  font-size: 16px;
  font-weight: 500;
}
.faq-content-st h3:after {
  background: #4e4e4e;
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 90px;
  height: 10px;
}
.owl-theme .owl-nav {
  margin-top: 10px;
}
.faq-stt .owl-theme .owl-nav [class*=owl-] {
  background: #9bbc2a;
  width: 25px;
  height: 30px;
  color: #fff;
}
button.owl-prev i {
  transform: rotate(90deg);
}
.faq-stt .owl-nav {
  position: absolute; 
  right: 10px;
  bottom: -44px;
}
.faq-stt {
  position: relative;
  z-index: 9;
}
.home-details.products.faq-stt {
  padding-bottom: 60px;
}

.contact-us-details {
  background: url(./assets/images/last_page.png) no-repeat scroll 0 0;
  background-size: cover;
  padding: 10px 0 100px;
}
.contact-us-details h2 {
  border-bottom: 3px solid #a5a2a1;
  font-size: 30px;
  border-bottom: 1px solid #f58220;
  padding-bottom: 5px;
  margin-bottom: 10px;
  display: inline-block;
  text-align: left;
  display: block;
  color: #f58220;
  padding-top: 15px;
   }
.contact-us-details h2 span {
  font-weight: 700;
}
.contact-us-details p.text-left {
  font-size: 12px;
  color: #fff;
  font-weight: 500;
  line-height: 20px;

}
.call-action h3 {
  color: #f58220;
  margin-bottom: 5px;
  font-size: 28px;
}
.call-action a {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}
.contact-us-details span {
  color: #fff;
  
}
.call-action.tt {
  text-align: left;
  margin-top: 25px;
}
.contact-us-details .call-action span {
  font-size: 16px;
}
.call-action ul {
  display: -webkit-inline-box;
  margin-left: 10px;
}
.call-action ul li {
  margin-right: 10px;
}
.call-action ul li {
  list-style: none;
  font-weight: 500;
}
.call-action ul { 
  padding: 0;
}
.call-action ul li i {
  color: #fff;
  font-size: 25px;
}
.contact-us-details h2 span {
  color: #f58220;
}


#myModal .modal-body, #myModalwhat .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 16px 20px 20px;
}
#myModal12 {
  overflow-x: hidden;
}
.modal.faq-popup {
  padding-right: 0!important;
}
#myModal.modal, #myModalwhat.modal {
  padding-right: 0!important;
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.fade .modal-dialog {
  transition: transform .3s ease-out; 
}
#myModal .modal-content, #myModalwhat .modal-content {
  background: url(./assets/images/slider_expansion_bg.png) no-repeat scroll 0 0;
  background-size: cover;
  border-radius: 50px;
  text-align: center;
}
#myModal .close, #myModalwhat .close {
  float: right;
  font-size: 25px;
  font-weight: 700;
  line-height: 1;
  color: #B1CB1F;
  text-shadow: none;
  opacity: 1;
  position: absolute;
  right: 40px;
  top: 28px;
  z-index: 9;
  border: 2px solid #B1CB1F;
  padding: 0;
  border-radius: 50px;
  width: 35px;
  height: 35px;
}

#myModal12 .close {
  float: right;
  font-size: 25px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: none;
  opacity: 1;
  position: absolute;
  right: 28px;
  top: 28px;
  z-index: 9;
  border: 2px solid #000;
  padding: 0;
  border-radius: 50px;
  width: 35px;
  height: 35px;
}
.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}
.modal-dialog {
  position: relative;
  width: auto; 
  pointer-events: none;
}
#myModal p, #myModalwhat p {
  color: #fff;
  font-size: 16px; 
  font-weight: 500;
  margin-bottom: 7px!important;
}
#myModal .stcoksherpa-div, #myModalwhat .stcoksherpa-div {
  border: 1px solid #B1CB1F;
  border-radius: 25px;
  padding: 15px;
  margin-top: 50px;
}
#myModal .stcoksherpa-div p, #myModalwhat .stcoksherpa-div p, .stcoksherpa-div h3 {
  color: #B1CB1F;
  font-size: 20px;
  margin-bottom: 8px;
  
}
.faq-popup h3 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 20px;
  text-align: left;
  position: relative;
  padding-bottom: 15px;
  display: table-cell;
  font-weight: 400;
  }
.lpage .faq-popup .modal-content:after {
  position: absolute;
  content: "";
  bottom: 0;
  background: url(../src/assets/images/faq-bg.png) no-repeat scroll 0 0;
  background-size: cover;
  background-position: bottom;
  width: 200px;
  height: 320px;
  left: 15px;
  z-index: 1;
}
.lpage .faq-popup .modal-content {
  border-radius: 50px;
  text-align: center;
  top: 0;
  height: 90vh;
  background: #f48120;
  position: relative;
}
.faq-popup .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 45px 25px;
}
.faq-popup .modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0;
  border-bottom: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.faq-popup h3:after {
  background: #fff;
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 90px;
  height: 10px;
}
.faq-popup p {
  color: #000;
  text-align: left;
  margin-top: 10px;
  
  font-size: 16px; 
  
  font-weight: 500;
  margin-bottom: 10px!important;
}

#myModal12 .modal-content{border-radius: 0px;background:transparent;}
#myModal12 .modal-body{padding: 0px 0px 0px;background: linear-gradient(to bottom, #58c7e1 50%, #b0b1b2 50%);}
#myModal12 .modal-content:after{display: none;}
#myModal12 .logo-section p{width: 77%;padding: 7px 7px;border: 2px solid #000;font-size: 14px;margin: 12px 15px;
text-align: center;}
#myModal12 .logo-section p strong{font-size: 18px;}
#myModal12 .logo-section img{
  width: 100%;
}
.ratios {
  width: 100%;
  padding: 15px; 
  z-index: 99999;
  position: relative;
  background: linear-gradient(180deg,transparent 50%,#b0b1b2 0);
}
.ratio1 {
  background: #fff;
  width: 100%;
  display: inline-block;
  border: 2px solid #f07e01;
  border-radius: 30px;
  height: 100%;
  text-align: center;
  padding: 6px 0 0;
}
.ratio2 {
  height: auto;
  margin-top: 4%;
  border: 1px solid #fff;
  background: #f07e01;
}
.ratio3 {
  height: 100%;
  margin-top: 4%;
  background: url(./assets/images/04.png);
  background-size: contain;
  position: relative;
  overflow: hidden;
}
.ratio3:before {
  width: 140px;
  right: -30px;
  top: -33px;
}
.ratios h6 {
  font-size: 15px;
  font-weight: 700;
  
}
.ratios ul {
  flex-wrap: wrap;
  width: 90%;
  margin: 0 auto;
  padding: 0;
}
.ratios li, .ratios ul {
  display: flex;
  justify-content: center;
} 
.ratios li:first-child {
  background: #63bbe1;
}
.ratios li {
  font-size: 11px;
}
.ratios li, .ratios ul {
  display: flex;
  justify-content: center;
}
.ratios li { 
  width: 40%;
  font-weight: 700;
  line-height: 11px;
  align-items: center;
  padding: 5px 0;
  margin: 0 10px 10px;
}
.ratios ul li {
  list-style: none;
  
  font-weight: 500;
}
.ratios li:nth-child(3), .ratios li:nth-child(6) {
  background: #0092cb;
}
.ratios li:nth-child(4), .ratios li:nth-child(5) {
  background: #1f77a7;
}
.ratios li:nth-child(2), .ratios li:nth-child(7) {
  background: #a0d6ee;
}
.ratio2 li {
  margin: 0 5px;
  width: 19%;
  background: #fff!important;
}
.ratio2 ul {
  margin: 5px auto;
  padding: 0 5px;
}
#myModal12 .bth {
  text-decoration: underline;
  margin-top: 0;
  color: #0f1012;
  cursor: pointer;
}

/*------Login Page-----*/
.form-section h1 {
  font-size: 50px;
  border-bottom: 3px solid #a5a2a1;
  padding-bottom: 5px;
  margin-bottom: 5px;
  text-align: center;
  font: 400 Roboto,Helvetica Neue,sans-serif;
}
.form-section h1 span {
  text-transform: uppercase;
  font-weight: 700;
}
.form-section h2 {
  text-align: center;
  font-size: 22px;
  color: #f48120;
  margin-top: 10px;
  
  font-weight: 500;
}
.login-home {
position: absolute;
top:0;
right: 0;
padding: 15px;
}
.form-section h3 {
  
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  margin-bottom: 15px;
  color: #000;
}
.form-section input {
  margin-bottom: 10px;
  border-radius: 0;
  border: 2px solid #a5a2a1;
  
  font-weight: 500;
  font-size: 16px;
}
.click_btn.add {
  border: 0!important;
  margin: 0 auto;
  display: block;
  text-align: center;
  font-size: 20px;
}
.form-section .login-btn {
  background: transparent;
  border: 0;
  text-decoration: underline;
  padding-top: 15px;
  text-align: center;
  margin: 0 auto;
  display: table;
  color: #000;
}
.faq-popup .modal-content {
  background: #f48120;
}
.faq-popup .close {
  float: right;
  font-size: 25px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: none;
  opacity: 1;
  position: absolute;
  right: 40px;
  top: 28px;
  z-index: 9;
  border: 2px solid #000;
  padding: 0!important;
  border-radius: 50px;
  width: 35px;
  height: 35px;
}
.modal-header {
  border-bottom: none;
}
#myModaln1 input {
  margin-bottom: 10px;
  font-size: 14px;
  border-radius: 0;
  border: 1px solid #958783;
}
#myModaln1 p {
  text-align: center;
  color: #000; 
  margin-top: 10px;
  line-height: 20px;
  font-size: 16px; 
  
  font-weight: 500;
  margin-bottom: 10px!important;
}
.cnt {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  user-select: none;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid #000;
}
.cnt input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.sbtn .click_btn.add {
  display: inline-flex!important;
  border: 1px solid #000!important;
  justify-content: center;
  width: 50%;
  background: transparent;
  height: 35px;
  line-height: 33px;
  font-size: 18px!important;
}
#myModaln1 a {
  text-decoration: underline;
  color: #000;
}
.cnt input:checked~.checkmark:after {
  display: block;
}
.cnt .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #000;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/*------Free Common Css (header,footer,page)-----*/
.stockhead {
  background: #f58220;  
  text-align: center;
  font-family: 'AvantGarGotltcTEE';
  padding: 10px 0;
  color: #2b2220;
  font-size: 12px;
  margin-top: 10px;
} 
.sheadbdr {
  border-right: 2px solid #fff;
}
.stockhead a {
  color: #000;
  font-family: 'AvantGarGotltcTEE';
  font-weight: 500;
  font-size: 12px;
}
.stockhead a:hover {
 text-decoration: none;
}
.wel {
  background: #57585a;  
  min-height: 400px;
}
app-autocomplete input
{
  box-sizing: border-box;
    border: none;
    box-shadow: none;
    outline: 0;
    background-color: #fff;
    color: #000000 !important; 
    padding: 0 15px;
    line-height: 40px;
    height: 40px;
    border-radius: 3px !important;
}
industry-autocomplete input
{
  box-sizing: border-box;
    border: none;
    box-shadow: none;
    outline: 0;
    background-color: #fff;
    color: #000000 !important; 
    padding: 0 15px;
    line-height: 40px;
    height: 40px;
    border-radius: 3px !important;
}

.clearable{
  position: relative;
  display: inline-block;
  width: 100%;
}
.clearable input[type=text]{
  padding-right: 24px;
  width: 100%;
  box-sizing: border-box;
}
.okto_popup
{
  font-family:AvantGarGotltcTEE;
  text-align: left;
  color: #000;
  list-style-type:lower-alpha
}
.clearable__clear{ 
  position: absolute;
  right:0; top:0;
  padding: 4px 8px;
  font-style: normal;
  font-size: 2em;
  user-select: none;
  cursor: pointer;
}
.searchbox input {
  text-align: center;
  font-size: 14px;
  font-family: AvantGarGotltcTEE;
  
}
.stars1, .stars2, .moon1 {
  position: absolute; 
}
.stars1 {
  top: 184px;
  right: 30px;
}
.stars2 {
  top: 178px;
  right: 3px;
  width: 35%;
}
.moon1 {   
  right: 2px;
  top: 230px; 
} 
.comn .btn.btn-info{
  color: #000;
  background-color: #f58220;
  border-color: #f58220;
  outline: none;
  font-weight: 700;
  font-size: 12px;
}
.comn .btn.btn-info:hover {
  color: #000;
  background-color: #f58220;
  border-color: #f58220;
  outline: none;
  box-shadow: none;
}
.comn .btn.btn-info:focus, 
.comn .btn.btn-info.focus { 
  outline: none;
  box-shadow: none !important;
  background-color: #f58220;
  border-color: #f58220;
  color: #000;
}
.comn .btn.btn-info.active, 
.comn .btn.btn-info:active 
{
  outline: none; 
  background-color: #f58220;
  border-color: #f58220;
}
.comn .btn.btn-info.active.focus,
.comn .btn.btn-info.active:focus,
.comn .btn.btn-info.active:hover,
.comn .btn.btn-info:active.focus,
.comn .btn.btn-info:active:focus,
.comn .btn.btn-info:active:hover 
{
  color: #fff;
  background-color: #f58220;
  border-color: #f58220;
  outline: none;
  box-shadow: none;
}

.comn .srheadblk
 {
 text-align: center;
}
.comn .srheadblk p {
  color: #f58220;
  font-size: 8px;
  border: 1px solid #f58220;
  border-radius: 15px; 
  line-height: 15px;
  font-family:avantgargotitcteedem;
  font-weight: 700;
  margin: 0;
}
.comn .srdetail {
  background-color:  #f58220;
  border: 1px solid #fff;
  height: 36px;
}
.comn .srdetail .srdetailblk .srdetailnum {
  font-size: 14px;   
  padding: 5px;
  font-weight: 600;
  text-align: right;
  
}
.comn .srdetail .srdetailblk .srdetailtxt {
  font-size: 10px; 
   line-height: 13px;
  font-weight: 600;
  margin-top: 4px;
}
.comn .srdetail .srdetailblk p {
  margin: 0;
  padding: 0;
}
.comn .srdetailnumblk {
  width: 20%;
  display: inline-block;  
  vertical-align: top;
}
.comn .srdetailtxtblk {
  width: 80%;  
  display: inline-block;
}
.comn .aidetail {
  background-color:  #fff;
  border: 1px solid #f58220;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: avantgargotitcteedem;
}
.comn .aidetail .aidetailblk {
  text-align:  center;
}
.comn .aidetail .aidetailblk p { 
  
  font-family: avantgargotitcteedem;
  margin: 0;
  padding-top: 2px;
  font-size: 12px;
  
}
.comn .aadetail {
  border: 1px solid #f58220;
  height: 36px; 
  display: flex;
  align-items: center;
  font-weight: bold;
}
.comn .aadetail .aadetailblk p {
  font-size: 10px;   
  color: #f58220;
  padding: 0px 5px;
  margin: 0;
  font-family: avantgargotitcteedem;
}
.comn .oktodetail {
  background-color:  #fff;
  border: 1px solid #f58220;
  height: 36px;
  
}
.comn .oktodetail .oktodetailblk p { 
  font-size: 12px; 
  color: #000;
  line-height: 15px;
  font-family: avantgargotitcteedem;
  padding: 3px 3px 0; 
  margin: 0;
}
.comn .oktoaidetail {
  background-color:  #f58220;
  border: 1px solid #f58220;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.comn .oktoaidetail .oktoaidetailblk p {
  margin: 0;
  padding-top: 2px;  
  font-weight: 600;
  text-align: center;
  margin: 0;
}
.comn .oktoaadetail {
  border: 1px solid #f58220;
  height: 36px;
  display: flex;
  align-items: center;
}
.comn .oktoaadetail .oktoaadetailblk p {
  font-size: 10px; 
  font-family: avantgargotitcteedem;
  color: #fff;
  padding: 0 5px;
  margin: 0;
  font-weight: bold;
}


.sanalysis { 
  background: url(../src/assets/images/shapen.png);
  background-color: #3c3b3a;
  background-repeat: no-repeat;
  background-size: contain; 
  margin: 0px auto;  
  position: relative;
  padding-left: 19%;
  padding-bottom: 10px;
  padding-top: 25px;
  padding-right: 10px;
  border: 1px solid #f58220;
}
          
.sanalysis .sahead {
  font-size: 10px;
  
  color:#000;
  background: #fff;
  display: inline-flex;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-weight: 600;
  padding:1px 5px;
  margin-left:-5%;
  position: absolute;
  top:0;
}
.sacontent p { 
  font-size: 10px;
  text-align: left;
  margin-bottom: 0px;
  font-family: avantgargotitcteedem;
  
}
.sanalysis .sacontent p {
  color:#f58220; 
  
}
.sanalysis .sacontent p:first-child{color: #fff;}

.sanalysis .sacontents
{
  color: #fff;
  font-size: 12px;
  text-align: left;
  margin-bottom: 0px;
  padding: 0;
  font-family: avantgargotitcteedem;
  text-transform: uppercase;
}

.orangeclr
{
  color: #f58220;
  font-family:avantgargotitcteedem;
  margin-top:10px;
}
.orangeclrwithol
{
  padding-left:10px;
  color: #f58220;
  font-family:avantgargotitcteedem;
}

.with-arrow .nav-link.active {
  position: relative;
}

.with-arrow .nav-link.active::after {
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #f58220;
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
}
.nav-item .border {
  border: 1px solid #fff !important;
} 


.dareport {
  text-align: center;
  border-bottom: 1px solid #ebebeb; 
  padding-top: 7px;
}
.dareport p span {
  color: #fff; 
  font-size: 12px;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  background-color: #f58220;
}
.nav-pills  a {
  color: #fff;
}
.dtlstockdtls {
  margin: 15px 0;
}
.dtlstockdtls p {
 margin-bottom: 0;
 color: #fff;  
 font-size: 10px;
 padding: 5px 0;
}
.bdrl {
  border-left: 1px solid #f58220;
}

 
#aianalysis1 .rmpadding .col-3, #aianalysis1 .rmpadding .col-9  {
padding: 0;
}
#aianalysis1 .aiblock .aitxt {  
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  background-color: #f58220;
  text-align: center;
  display: flex;
  justify-content: center; 
  align-items: center;
  height: 29px;
}
#aianalysis1 .aiblock .aitxt p {
  color: #000; 
  
  font-size: 8px;
  margin-bottom: 0; 
  line-height: 8px;
  font-weight: 700;

}
#aianalysis1 .aiblock .aitxt p span {
  font-size: 7px;
}
#aianalysis1 .aiblock .aivalue {
  border: 1px solid #f58220; 
  background-color: #fff; 
  text-align: center;
  display: flex;
  justify-content: center; 
  align-items: center;
  height: 26px;
}
#aianalysis1 .aiblock .aivalue p {
 color: green; 
  
 font-weight: bold;
 font-size: 13px;
 margin-bottom: 0;
}
#aianalysis1 .headstyle {
    text-align: left;
    border: 1px solid #fff;
    padding: 5px;
    font-size: 11px;
     
    margin: 0;
}
#aianalysis1 .headstyle {
  color: #fff;
}
#aianalysis1 .headstyle span {
  color: #f58220;
}

#aianalysis1 .oktoblock .oktotxt {  
  border: 1px solid #f58220; 
  background-color: #fff; 
  text-align: center;
  display: flex;
  justify-content: center; 
  align-items: center;
  height: 29px;
}
#aianalysis1 .oktoblock .oktotxt p {
  color: #000; 
 
 font-size: 8px;
 margin-bottom: 0;
 line-height: 8px;
 font-weight: 700;
}
#aianalysis1 .oktoblock .oktovalue {
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  background-color: #f58220;
  text-align: center;
  display: flex;
  justify-content: center; 
  align-items: center;
  height: 26px;
}
#aianalysis1 .oktoblock .oktovalue p {
 color: green; 
  
 font-weight: bold;
 font-size: 13px;
 margin-bottom: 0;
}
#aianalysis1 .aihead{
  text-align: center;
}
#aianalysis1 .aihead p{
margin: 0;
 
color: #fff;
font-weight: 600;
text-decoration: underline;
}





#overview1 .rmpadding .col-3, #overview1 .rmpadding .col-9  {
  padding: 0;
  }
  #overview1 .aiblock .aitxt {  
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    background-color: #f58220;
    text-align: center;
    display: flex;
    justify-content: center; 
    align-items: center;
    height: 29px;
  }
  #overview1 .aiblock .aitxt p {
    color: #000; 
    
    font-size: 8px;
    margin-bottom: 0; 
    line-height: 8px;
    font-weight: 700;
  
  }
  #overview1 .aiblock .aitxt p span {
    font-size: 7px;
  }
  #overview1 .aiblock .aivalue {
    border: 1px solid #f58220; 
    background-color: #fff; 
    text-align: center;
    display: flex;
    justify-content: center; 
    align-items: center;
    height: 26px;
  }
  #overview1 .aiblock .aivalue p {
   color: green; 
    
   font-weight: bold;
   font-size: 13px;
   margin-bottom: 0;
  }
  #overview1 .headstyle {
      text-align: left;
      border: 1px solid #fff;
      padding: 5px;
      font-size: 11px;
       
      margin: 0;
  }
  #overview1 .headstyle {
    color: #fff;
  }
  #overview1 .headstyle span {
    color: #f58220;
  }
  
  #overview1 .oktoblock .oktotxt {  
    border: 1px solid #f58220; 
    background-color: #fff; 
    text-align: center;
    display: flex;
    justify-content: center; 
    align-items: center;
    height: 29px;
  }
  #overview1 .oktoblock .oktotxt p {
    color: #000; 
   
   font-size: 8px;
   margin-bottom: 0;
   line-height: 8px;
   font-weight: 700;
  }
  #overview1 .oktoblock .oktovalue {
    border-bottom: 1px solid #fff;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    background-color: #f58220;
    text-align: center;
    display: flex;
    justify-content: center; 
    align-items: center;
    height: 26px;
  }
  #overview1 .oktoblock .oktovalue p {
   color: green; 
    
   font-weight: bold;
   font-size: 13px;
   margin-bottom: 0;
  }
  #overview1 .aihead{
    text-align: center;
  }
  #overview1 .aihead p{
  margin: 0;
   
  color: #fff;
  font-weight: 600;
  text-decoration: underline;
  }
  #swot1 .aihead{
    text-align: center;
  }
  #swot1 .aihead p{
  margin: 0;
   
  color: #fff;
  font-weight: 600;
  text-decoration: underline;
  }

.green
{
    color: green;
    font-family: avantgargotitcteedem;
    font-size: 12px;
    font-weight: 700;
}

.pt-5,.py-5
{
  padding-bottom:15px !important;
}

.red
{
   color: red !important;
   font-family: avantgargotitcteedem;
   font-size: 12px;
   font-weight: 700;
}

.green-moon {
    /* width: 22px;
    height: 22px;   */
    border-radius: 50%;
    /* background: yellow; */
    color: green; 
    margin: 0 auto !important;
    font-size: 12px;
    font-family: avantgargotitcteedem;
    font-weight: 700;
  }
  
  .white-moon {
    width: 22px;
    height: 22px;  
    border-radius: 50%;
    background: white;
    margin: 0 auto !important;
    font-size: 12px;
    font-family: avantgargotitcteedem;
    font-weight: 700;
    }
  .orange-moon {
    width: 22px;
    height: 22px;  
      border-radius: 50%;
      background: white;
      margin: 0 auto !important;
      font-size: 12px;
      font-family: avantgargotitcteedem;
      font-weight: 700;
    }

.mrb15 {
  margin-bottom: 15px;
 }
/*------Free Dashboard Page-----*/
.inrusrDtls {
  padding-top: 10px;
  
}
.headp { 
  line-height: 18px;
  margin-bottom: 7px;
  color: #f58220;
  font-family: avantgargotitcteedem;
  
}
.freedb .comparetxt {
  text-align:  right;
  
}
.freedb .comparetxt span {
  font-weight: 500;
  
  color: #fff;
  font-size: 15px;
  text-decoration: underline;
}
.freedb .comparetxt i {
  color: #f58220;
  
}
.freedb .portfoliotxt {
  text-align:  center;
}
.freedb .portfoliotxt span {
  font-weight: 500;  
  color: #fff;
  font-size: 10px;
  text-decoration: underline;
  cursor: pointer;
}
.freedb .portfoliotxt i {
  color: #f58220;
  font-size: 10px;
  
}
.portfolioalign
{
  margin-bottom: 12px;
  text-align: center;
  color:#ffff;
  cursor: pointer;
  text-decoration: underline;
}
.paidedt {
  background-color: #d4b16d;
}
.our-products ol {
  font-family: AvantGarGotltcTEE;
  
  display: block;
  padding: 0 0 0 26px;
  list-style: none;
        overflow: hidden;
  counter-reset: numList;
}   

.our-products ol>li {
  counter-increment:item;    
  font-family: AvantGarGotltcTEE;
  font-size: 16px;
  /* float: left;   */
  position: relative;
  padding-left: 5px;
  padding-bottom: 4px;
    
}
.our-products .paidedtcnt ol>li:before {
  content:counter(item) ;
  margin-right: 5px;
  padding: 0px;
  display: block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border:1px solid #000; 
  color: #000;
  text-align: center; 
  font-family: AvantGarGotltcTEE;
  float: left;
  position: absolute;
  left: -26px;
  width: 25px; 
  height: 25px;
  font-weight: 600;
  
}
.our-products ol>li:before {
  content:counter(item) ;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border:1px solid #fe862c; 
  color: #fe862c;
  text-align: center; 
  font-family: AvantGarGotltcTEE;
  float: left;
  position: absolute;
  left: -26px;
  width: 25px; 
    height: 25px;
  
}
.fredtimg {
  text-align: center; 
  margin-top:3px;
}
.fredtimg img {
  width: 100%;
}
.fredtcnt {
  font-family:AvantGarGotltcTEE;
  padding: 5px 0;
}
.fredtcnt p {
  font-family: AvantGarGotltcTEE; 
  font-size: 16px;
  font-weight: 500;
  padding-top: 0px;
}
 
.paidedtcnt p {
  font-family: AvantGarGotltcTEE; 
  font-size: 16px;
  font-weight: 500;
  padding-top: 5px;

}
.prodemail {
  margin-top: 10px;
  padding: 10px;
  border: 2px solid #afca05;
  background: #afca05;
}

/*------Free Compare Page-----*/
.compare app-autocomplete input
{ 
  height: 30px;
  
}
.dasboard app-autocomplete input
{
  height: 30px;
}
.compare .clearable__clear{  
  padding: 3px 8px; 
  color: #000000;
  font-size: 1.5em;
}


.compare {
  padding-top: 20px;
 }
 .compare .complus {
 text-align: center;
}
.compare .complus i {
  color: #f58220;
  font-weight: 600;
  font-size: 18px;
  padding: 4px 0;
 }
 .gviewtxt {
   border-bottom: 1px solid #fff;
 }

 .gviewtxt p {  
  color: #fff;
    
  font-size: 10px;
  margin-bottom: 0;
 }

.compareres .cmpsthead {
  text-align: center;
}
.compareres .cmpsthead p {
  color: #fff;
    
  font-weight: 600;
  font-size: 10px;
  margin-bottom: 0;
  padding-top: 5px;
}

.compareres .compadd .row .col-3 {
  padding: 0 5px;
}
.compareres .compadd .row .col-2 {
  padding: 0 2px;
}
.compareres .compadd .row .col-1 {
  padding: 0 2px;
}
 

.compareres .cmpsrdetail .cmpsrdetailblk .cmpsrdetailnum {
  font-size: 14px; 
  
  padding: 2px;
  font-weight: 600;
  color: #f58220;
}
.compareres .cmpsrdetail .cmpsrdetailblk .cmpsrdetailtxt {
  font-size: 8px; 
   
  font-weight: 600;
  margin-top: 2px;
  color: #f58220;
}
.compareres .cmpsrdetail .cmpsrdetailblk p {
  margin: 0;
  padding: 0;
}
.cmpsrdetailnumblk {
  width: 20%;
  display: inline-block;  
  vertical-align: top;
}
.cmpsrdetailtxtblk {
  width: 80%;  
  display: inline-block;
}

.compareres .cmpoktohead {
  text-align: center;
}
.compareres .cmpoktohead p {
  color: #fff;
    
  font-weight: 600;
  font-size: 8px;
  margin-bottom: 0;
  padding-top: 5px;
}

.compareres .cmpstname {
  border: 1px solid #fff;
  text-align: center;
  display: flex;
  justify-content: center; 
  align-items: center;
}
.compareres .cmpstname p {
  color: #fff;     
  font-size: 10px;
  margin-bottom: 0;
  padding: 3px 2px;
}

.compareres .cmpstval {
  border: 1px solid #f58220;
  text-align: center;
  display: flex;
  justify-content: center; 
  align-items: center;
}
.compareres .cmpstval p {
  color: #f58220;
     
  font-size: 12px;
  margin-bottom: 0;
  padding: 3px 2px;
}


.compareres .cmpstoktoval {
  border: 1px solid #fff;
  text-align: center;
  display: flex;
  justify-content: center; 
  align-items: center;
}
.compareres .cmpstoktoval p {
  color: #fff;
     
  font-size: 12px;
  margin-bottom: 0;
  padding: 3px 2px;
}

.compareres .cmpstexpand { 
  text-align: center;
  display: flex;
  justify-content: center; 
  align-items: center;
}
.compareres .cmpstexpand i {
  color: #f58220;  
  font-size: 13px;  
}

.mscriteria {
  text-align: center;
  border: 1px solid #fff;
  border-radius: 20px;
  margin: 0;
}

.mscriteriahead p {
  margin: 0;
  font-size: 10px;
  color:  #f58220;
  text-decoration: underline;
     
}

.mscriteriadtls {
  padding: 0 5px;
}

.mscriteriadtls p {
  font-size: 10px;
  margin: 0;
  color:  #fff;
     
}
.valuesidenty {
  color:  #f58220;
  font-weight: 600;
  font-size: 12px;
}


 











@media only screen and (max-width: 374px) {
  .comparetxt{
    margin-left: 12px;
  }
}


@media only screen and (min-width: 375px) {
  .comparetxt
  {
    margin-left: 8px;
  }
}

@media only screen and (max-width: 359px) {
  .stockhead a {
    font-size: 12px;
  }
  .headp {
    font-size: 14px;
  }
  .headp span {
    font-size: 14px;
  }
  .stars1, .stars2 {
    width: 50px;
   }
   .moon1 {
    width: 40px;
   }
   .subscription-details p{
     font-size: 14px;
   }
   .stricke-line {
    font-size: 14px;
   }
   .subscription-view a {
     font-size: 13px;
   }
   .products-check p {
    font-size: 14px;
   }
   .team-image ul.social-links li i {
     font-size: 20px;
   }
   .modal-dialog {
    margin: 0.5em;
  }
  #myModal12 .logo-section p {
    font-size: 12px;
  }
  .ratios {
   margin-top: -220px;
  }
  .stockelipse
  {
    text-overflow: ellipsis;width: 79px;white-space: nowrap;overflow: hidden;
  }
}

 
@media only screen and (min-width: 360px) and (max-width: 575px) {
  #home .homedtls {
    margin-top: -25px;
  }
  .headp {
    font-size: 14px;
  }
  .headp span {
    font-size: 14px;
  }
  .stars1, .stars2, .moon1  {
    width: 50px;
  }
  .modal-dialog {
    margin: 0.5em;
  }
  .ratios {
    margin-top: -250px;
   }
   .stockelipse
  {
    text-overflow: ellipsis;width: 79px;white-space: nowrap;overflow: hidden;
  }
}
/* 
@media only screen and (max-width: 576px) {
  #home .homedtls {
    margin-top: -25px;
  }
}
@media only screen and (min-width: 577px) and (max-width: 768px) {
  #home .homedtls {
    margin-top: -100px;
  }

}
@media only screen and (min-width: 769px) and (max-width: 1824px) {
  #home .homedtls {
    margin-top: -200px;
  }

}  */

/* By Industry */
.rtntxt a {
  color: #fff;
  text-align: center;
  font-size: 14px; 
  text-decoration: underline;
  padding-bottom: 15px;
  }
  .inshead {
      text-align: center;
      padding-top: 15px;
      
  }
  .moonshotdiv .moonshottxt {
      color: #f58220;
      margin-bottom: 0;
      font-weight: 600;
      font-size: 14px; 
      margin-top: 15px;
  }
  .moonshotdiv .moonshottxt span {  
      color: #f58220;
      padding-bottom: 2px;
      border-bottom: 1px solid #f58220;
  }
  .acctypetxt {
      color: #000;
      font-weight: 600;
      font-size: 14px;
      margin-top: 3px;
      margin-bottom: 10px;
  }
   
  .acctypetxt span {
      background: #f58220;
      padding: 5px 10px;
      border-radius: 0 0 10px 10px;
      font-weight: 600;
    
  }

  .searchinrdiv  {
      padding: 5px;
  }
  
  .searchdiv .row .col-12 .searchinrdiv {
      border: 1px solid #acacac;
      border-radius: 20px;
  }
  .searchinrdiv .paidver {
      background: #fff;
      color: #000;
  }
  
  .searchinrdiv .freever {
      background: #acacac;
      color: #fff;
  }
  
  .searchinrdiv .cntnum {
      width: 10%;
      display: inline-block;
      text-align: center;
  }
  .searchinrdiv .cntnum  span{
      border-radius: 30px;
      margin-left: 3px;
      padding: 3px 8px;
      font-weight: 600; 
  }
  
  .searchinrdiv .qstxt {
      text-align: center;
      width: 85%;
      display: inline-block
  }
  
  .searchinrdiv .qstxt  span{
   font-size: 14px;
  }
  .links
  {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .links li{
    margin: 10px 10px 0px;
    list-style: none;
  }
  .links a {
    color: #fff;
    text-decoration: underline;
    font-size: 14px !important;
    justify-content: space-around;
    width: 50%;
}
  .ogcolour {
      color: #f58220;
  }
  .whtcolour {
      color: #fff;
  }
  .disablecolour {
      color: #939598;
  }
  .industrypaidclr
  {
    color: #000;
    
  }
  .industryfreebox
  {
    margin-left: 0;margin-bottom: 15px;margin-right: 0;
  }
  .industryfreeboxextra
  {
    margin-left: 0;margin-bottom: 30px;margin-right: 0;
  }
  .industryelipse
  {
    text-overflow: ellipsis;white-space: nowrap;overflow: hidden;color: #fff;
  }
  .searchresdiv {
      border: 1px solid #acacac;
      color: #fff;
  }
  .searchrespdiv {
      border: 1px solid #f58220;
      color: #000;
  }
  .schprefix  {    
      width: 25%;
      display: inline-block;
      text-align: center;
  }
  .fversion {
      background: #acacac;
  }
  .pversion {
      background: #f58220;
  }
  .schprefixval {
      width: 75%;
      display: inline-block;
      text-align: center;
  }
  .schprefix span{    
      font-size: 14px;
      font-weight: 600; 
  }
  
  .schprefixval span{
      font-size: 12px;
      color: #fff;
      
  }
  .rmpadd {
      padding: 2px;
      
  }
  .searchicon
  {
    transform:rotate(90deg);
  }


.btns a {
  background: #c26a1a;
  width: 60px;
  font-size: 12px;
}
.btns a span {
  color: #000 !important;
}
.click_btn {
  line-height: 44px;
  
}
.btns a::after {
  background: #f58220;
  margin: 7px 8px;
}
.btns a::after {
  content: '';
  position: absolute;
  background: #f48120;
  width: 100%;
  height: 100%;
  left: 0;
  margin: 10px 13px;
  border-radius: 10px;
}

.btns a {
  background: #d26e1e;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  transform: rotateZ(180deg);
}
.btns a span {
  transform: rotateZ(180deg);
  display: inline-block;
  z-index: 1029;
  color: #000;
  position: relative;
  font-weight: bold;
  
  
}
#myModaldash5 .modal-content{
  background-size: cover;
  border-radius: 50px;
  text-align: center;
background-color: #57585a;
top: 0px;
  height: 70vh;
border: 2px solid #f58220;

}

#myModaldash5 .modal-header .close{
padding: 3px 5px 5px 6px;
  margin: 0rem 0rem -1rem auto;
  color: #f58220;
  border: 2px solid #f58220;
  border-radius: 50px;
}

#myModaldash5  .close{
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
   text-shadow:0 0 black; 
   opacity: 1; 
}
#myModaldash5 .modal-body{
padding: 0;
display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;
}
.btns {  
  text-align: center;
}


.navbar {
  display: block !important;
  padding: 0;
}
.navbar .navsplit {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: .5rem 1rem;
}
.navbar-collapse {
margin: 0 15px !important;
}

.navbar-nav {
  background: url(./assets/images/dropdown_menu_bg.png) no-repeat scroll 0 0;
  background-size: cover;
  background-position: 50%;
}

.navbar-collapse ul li {
  padding: 15px 0;
  text-align: center;
  border-top: 1px solid #4e4e4e;
  border-bottom: 1px solid #4e4e4e;
}
.navbar-toggler .navbar-toggler-icon {
  background-image: none !important;
}
.navbar-toggler.collapsed span.navbar-toggler-icon i {
  font-size: 40px !important;
color:#fff;
}
.navbar-toggler span.navbar-toggler-icon i {
  font-size: 40px !important;
color:#fff;
}

.authhdr .navbar-toggler {
font-size: 25px;
}
.autocomplete-container .input-container input
{
  border-radius: 3px !important;
}
.pagination{
  display:inline-block !important;
  padding-left:0;
  margin:20px 0;
  border-radius:4px
}
.pagination>li
{
  display:inline;
}
.pagination>li>a,.pagination>li>span
{
  position:relative;
  float:left;
  padding:6px 10px;
  margin-left:-1px;
  line-height:1.42857143;
  color:#337ab7;
  text-decoration:none;
  background-color:#fff;
  border:1px solid #ddd
}
.pagination>li:first-child>a,.pagination>li:first-child>span
{
  margin-left:0;
  border-top-left-radius:4px;
  border-bottom-left-radius:4px
}
.pagination>li:last-child>a,.pagination>li:last-child>span
{
  border-top-right-radius:4px;
  border-bottom-right-radius:4px
}
.pagination>li>a:focus,.pagination>li>a:hover,.pagination>li>span:focus,.pagination>li>span:hover
{
  z-index:2;
  color:#23527c;
  background-color:#eee;
  border-color:#ddd;
}
.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover
{
  z-index:3;
  color:#fff;
  cursor:default;
  background-color:#337ab7;
  border-color:#337ab7
}
.pagination>.disabled>a,
.pagination>.disabled>a:focus,
.pagination>.disabled>a:hover,
.pagination>.disabled>span,
.pagination>.disabled>span:focus,
.pagination>.disabled>span:hover
{
  color:#777 !important;
  cursor:not-allowed;
  background-color:#fff;
  border-color:#ddd
}
.pagination-lg>li>a,.pagination-lg>li>span
{
  padding:10px 16px;
  font-size:18px;
  line-height:1.3333333
}
.pagination-lg>li:first-child>a,
.pagination-lg>li:first-child>span
{
  border-top-left-radius:6px;
  border-bottom-left-radius:6px
}
.pagination-lg>li:last-child>a,
.pagination-lg>li:last-child>span
{
  border-top-right-radius:6px;
  border-bottom-right-radius:6px
}
.pagination-sm>li>a,
.pagination-sm>li>span
{
  padding:5px 10px;
  font-size:12px;
  line-height:1.5
}
.pagination-sm>li:first-child>a,
.pagination-sm>li:first-child>span
{
  border-top-left-radius:3px;
  border-bottom-left-radius:3px
}
.pagination-sm>li:last-child>a,
.pagination-sm>li:last-child>span
{
  border-top-right-radius:3px;
  border-bottom-right-radius:3px
}
.pager
{
  padding-left:0;margin:20px 0;
  text-align:center;list-style:none
}
.pager li
{
  display:inline
}
.pager li>a,
.pager li>span
{
  display:inline-block;
  padding:5px 14px;
  background-color:#fff;
  border:1px solid #ddd;
  border-radius:15px;
}
.pager li>a:focus,
.pager li>a:hover
{
  text-decoration:none;
  background-color:#eee
}
.pager .next>a,
.pager .next>span
{
  float:right
}
.pager .previous>a,
.pager .previous>span
{
  float:left
}
.pager .disabled>a,
.pager .disabled>a:focus,.pager .disabled>a:hover,
.pager .disabled>span
{
  color:#777;
  cursor:not-allowed;
  background-color:#fff
}
.mat-autocomplete-panel
{
  max-height:185px !important;
}
.orangetxt
{
  color: #f58220;;
}

/* ND-CSS */
#nd-header .navbar-toggler {
  border: none;
}

#nd-header button {
  outline: none;
  box-shadow: none;
}


#nd-header nav.navbar {
  border-bottom: none;
}


