@font-face {
	font-family: "GD Sherpa Medium"; 
    src: url('../fonts/100c7ffee4c602cfa9628a6c791c14cd.woff2') format('woff2'),
        url('../fonts/100c7ffee4c602cfa9628a6c791c14cd.woff') format('woff');
    font-weight: 500;
    font-style: normal;
	}

@font-face {
    font-family: 'AvantGarde Bk BT';
    src: url('../fonts/AvantGardeITCbyBT-Demi.woff2') format('woff2'),
        url('../fonts/AvantGardeITCbyBT-Demi.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AvantGarde Bk BT';
    src: url('../fonts/AvantGardeITCbyBT-DemiOblique.woff2') format('woff2'),
        url('../fonts/AvantGardeITCbyBT-DemiOblique.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'AvantGarde Md BT';
    src: url('../fonts/AvantGardeITCbyBT-Medium.woff2') format('woff2'),
        url('../fonts/AvantGardeITCbyBT-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AvantGarde Md BT';
    src: url('../fonts/AvantGardeITCbyBT-MediumOblique.woff2') format('woff2'),
        url('../fonts/AvantGardeITCbyBT-MediumOblique.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'AvantGarde Bk BT';
    src: url('../fonts/AvantGardeITCbyBT-BookOblique.woff2') format('woff2'),
        url('../fonts/AvantGardeITCbyBT-BookOblique.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'AvantGarde Bk BT';
    src: url('../fonts/AvantGardeITCbyBT-Book.woff2') format('woff2'),
        url('../fonts/AvantGardeITCbyBT-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
 
@font-face {
    font-family: 'AvantGarGotltcTEE';
    src: url('../fonts/AE10013T.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}










