@font-face {
  font-family: 'AvantGarGotltcTEE';
  src: url('../../fonts/AE10013T.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

p, h1, h2, h3, h4, h5, h6, span, a, button {
  font-family: AvantGarGotltcTEE; 
  line-height: 1.5;
}

#nd-nav-head {
  color: #000;
  font-size: 24px;
  border-bottom: 1px solid #000;
  margin-bottom: 0!important;
  font-weight: 700;
}

#nd-nav-p {
  font-size: 14px;
  text-align: center;
  color: #fff;
  margin-bottom: 5px;
  line-height: 20px;
  font-weight: normal;
}

.navbar-toggler-icon {
  background: none !important;
}

.fa.fa-navicon {
  font-size: 36px;
  color: #fff;
}

@media (min-width: 768px) {
  #nd-nav-split {
    display: none;
  }
}

@media (min-width: 768px) {
  #navcol-1 {
    margin: 0;
  }
}

@media (max-width: 576px) {
  #navcol-1 {
    margin: -10px 15px 0 15px;
  }
}

@media (min-width: 768px) {
  #nd-header .navbar-nav {
    background: none;
    -ms-flex-direction: row;
    flex-direction: row;
    width: 100%;
  }
}

@media (min-width: 768px) {
  #nd-header navbar li {
    width: 16.66%;
  }
}

@media (max-width: 576px) {
  .navbar.navbar-light.navbar-expand-md.navigation-clean.fixed-top {
    /* height: 78px; */
  }
}

@media (max-width: 767px) {
  #nd-content {
    margin-top: 40px;
  }
}

@media (max-width: 767px) {
  #nd-hme-desktop {
    display: none;
  }
}

#nd-hme-desktop a img {
  width: 100%;
  height: auto;
  display: block;
}

#nd-hme-mobile a img {
  width: 100%;
  height: auto;
  display: block;
}

.navbar.navbar-light.navbar-expand-md.navigation-clean.fixed-top {
  background: #58c7e1;
  padding: 0;
}

@media (min-width: 768px) {
  .navbar.navbar-light.navbar-expand-md.navigation-clean.fixed-top {
    background: none;
  }
}

#nd-hme-content {
  text-align: center;
}

#nd-hme-content h1 {
  font-size: 48px;
  border-bottom: 1px solid #000;
  margin-bottom: 10px;
  display: inline-block;
  font-weight: 500;
  margin-top: -25px;
}

@media (max-width: 576px) {
  #nd-hme-content .col p {
    padding: 0 30px;
  }
}

#nd-content .container-fluid {
  padding: 0;
  background: #D9DADA;
}

#nd-summit-content {
  width: 100%;
  margin: 0 auto;
}

#nd-summit-content {
  text-align: center;
}

@media (max-width: 300px) {
  #nd-summit-content .col-4 img {
    width: 50px;
    height: auto;
  }
}

@media (max-width: 576px) {
  #nd-summit-content .col-4 {
    padding: 0 4px;
  }
}

#nd-hme-content p {
  font-size: 16px;
  padding: 0 15px;
  margin-bottom: 10px;
}

#nd-summit-content {
  padding-bottom: 10px;
}

#nd-free-signup {
  text-align: center;
}

#nd-free-signup .click_btn {
  background: url("../../../assets/images/button.png") no-repeat scroll 0 0;
  background-size: cover;
  color: #000;
  width: 258px;
  height: 50px;
  display: inline-block;
}

#nd-free-signup {
  background: url("../../../assets/images/mountain-nd.jpg") no-repeat scroll 0 0;
  background-size: cover;
}

#nd-free-signup p {
  color: #fff;
  font-size: 16px;
  padding: 10px 0 15px 0;
  margin-bottom: 0;
}

@media (min-width: 769px) {
  #nd-summit p {
    text-align: center;
    font-size: 22px;
    padding: 10px 0;
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  #nd-summit p {
    text-align: center;
    font-size: 18px;
    padding: 10px 0;
    margin-bottom: 0;
  }
}

#nd-summit-content .col-4 p {
  font-size: 14px;
  margin-bottom: 0;
}

@media (min-width: 992px) {
  #nd-free-signup a {
    margin-top: 100px !important;
  }
}

#nd-free-signup a {
  line-height: 44px;
  font-size: 18px;
  margin-top: 60px;
}

@media (min-width: 768px) {
  #nd-hme-mobile {
    display: none;
  }
}

#nd-header .nav-link {
  color: #000;
  font-family: 'AvantGarGotltcTEE';
  font-weight: bold;
  cursor: pointer;
}

.nd-video-content {
  font-size: 18px;
  margin-bottom: 0;
}

#nd-video-img img {
  text-align: center;
  width: 90%;
  margin-top: 20px;
}

#nd-video-img {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 1200px) {
  .nd-video-content {
    font-size: 20px;
  }
}

@media (min-width: 1200px) {
  .nd-video-content {
    font-size: 20px;
  }
}

@media (min-width: 1200px) {
  .nd-video-content {
    font-size: 20px;
  }
}

@media (min-width: 1200px) {
  .nd-video-content {
    font-size: 20px;
  }
}

@media (min-width: 1200px) {
  .nd-video-content {
    font-size: 20px;
  }
}

@media (min-width: 576px) {
  #nd-video-img img {
    width: 200px !important;
  }
}

#nd-remove-padd-1 {
  padding: 0;
  margin-bottom: 15px;
}

#nd-remove-padd-1 {
  padding: 0;
  margin-bottom: 15px;
}

#nd-remove-padd {
  padding: 0;
  margin-bottom: 15px;
}

@media (min-width: 992px) {
  #nd-climb-1 h2 {
    font-size: 17px !important;
    margin-bottom: 10px;
  }
}

#nd-climb-1 h2 {
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  margin-top: 0;
  padding-left: 20px;
}

#nd-climb h2 {
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  margin-top: 0;
  padding-left: 10px;
}

@media (min-width: 992px) {
  #nd-climb-1 p {
    font-size: 15px !important;
    margin-bottom: 12px !important;
  }
}

#nd-climb-1 p {
  color: #000000;
  font-size: 14px;
  padding-left: 20px;
  margin-bottom: 10px;
}

#nd-climb p {
  color: #000000;
  font-size: 14px;
  padding-left: 10px;
  margin-bottom: 10px;
}

@media (min-width: 992px) {
  #nd-climb-1 h1 {
    font-size: 21px !important;
  }
}

#nd-climb-1 h1 {
  color: #EF7F1A;
  font-size: 18px;
  margin-top: 10px;
  text-decoration: underline;
}

#nd-climb h1 {
  color: #EF7F1A;
  font-size: 18px;
  margin-top: 10px;
  text-decoration: underline;
}

#nd-climb-1 p .fa.fa-check {
  color: #EF7F1A;
}

#nd-climb p .fa.fa-check {
  color: #EF7F1A;
}

#nd-Subscription-1 {
  background: #D9DADA;
  border-top: 2px solid #a5a2a1;
  border-right: 2px solid #a5a2a1;
  border-left: 2px solid #a5a2a1;
}

#nd-Subscription {
  background: #D9DADA;
  border-top: 2px solid #a5a2a1;
  border-right: 2px solid #a5a2a1;
  border-left: 2px solid #a5a2a1;
}


#nd-Subscription .row .col-6 {
  padding: 0 5px;
}

#nd-Subscription-1 .row .col-12 h1 {
  text-align: center;
  font-size: 16px;
  text-decoration: underline;
  margin-top: 15px;
}

#nd-Subscription .row .col-6 h1 {
  text-align: center;
  font-size: 16px;
  text-decoration: underline;
  font-weight: 600;
  margin-top: 20px;
}

#nd-Subscription h1 {
  text-align: center;
  font-size: 16px;
  text-decoration: underline;
  margin-top: 10px;
}

#nd-Subscription-1 p {
  font-size: 16px;
  text-align: center;
}

#nd-Subscription p {
  font-size: 16px;
  text-align: center;
}

#nd-left-div-1 {
  border-right: 1px solid #a5a2a1;
}

#nd-left-div-1 {
  border-right: 1px solid #a5a2a1;
}

#nd-left-div-2 {
  border-right: 1px solid #a5a2a1;
}

#nd-left-div {
  border-right: 1px solid #a5a2a1;
}

#nd-right-div-3 {
  border-left: 1px solid #a5a2a1;
}

#nd-Subscription-price-1 {
  border-bottom: 2px solid #a5a2a1;
  border-right: 2px solid #a5a2a1;
  border-left: 2px solid #a5a2a1;
}

#nd-Subscription-price {
  border-bottom: 2px solid #a5a2a1;
  border-right: 2px solid #a5a2a1;
  border-left: 2px solid #a5a2a1;
}

#nd-Subscription-price-1 h1 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0;
  text-align: center;
}

#nd-Subscription-price h1 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0;
  text-align: center;
}

#nd-left-div-3 {
  border-right: 1px solid #a5a2a1;
}

#nd-left-div-1 {
  border-right: 1px solid #a5a2a1;
}

#nd-right-div-3 {
  padding-top: 15px;
  padding-bottom: 15px;
}

#nd-Subscription-price-1 .row .col-6 {
  padding: 20px 0;
}

#nd-Subscription-price .row .col-6 {
  padding: 20px 0;
}

.nd-stricke-line {
  text-decoration: line-through red 2px;
}

@media (min-width: 992px) {
  #nd-Subscription-price-1 p {
    text-align: center;
  }
}

#nd-Subscription-price-1 p {
  font-size: 14px;
  margin-bottom: 0;
  padding-left: 10px;
  text-align: center;
}

#nd-Subscription-price p {
  font-size: 14px;
  margin-bottom: 0;
  padding-left: 10px;
  text-align: center;
}

#nd-Subscription-price a {
  text-align: center;
}

.nd-sub-button {
  text-align: center;
}

#nd-sub-button {
  text-align: center;
}

.nd-sub-button a {
  margin: 5px 0;
  background: url(../../../assets/images/button.png) no-repeat scroll 0 0;
  background-size: cover;
  color: #000;
  display: inline-block;
  line-height: 44px;
  font-size: 18px;
  border-radius: 10px;
  padding: 0 11px;
  text-decoration: none;
  width: 258px;
  height: 50px;
}

@media (min-width: 768px) {
  #nd-content-sec {
    display: none;
  }
}

@media (max-width: 767px) {
  .nd-content-sec-desktop {
    display: none;
  }
}

#nd-content-sec-1 .row .nd-lftdiv {
  padding: 0;
}

#nd-content-sec-1 .row {
  width: 100%;
  margin: 0 auto;
}

#nd-video-img-1 {
  text-align: center;
}

#nd-video-img {
  text-align: center;
}

#nd-Subscription-price-1 {
  width: 100%;
}

.row.nd-add-padd {
  padding-bottom: 10px;
  padding-top: 10px;
}

@media (min-width: 992px) {
  #nd-climb-1 {
    padding-left: 10%;
  }
}

@media (min-width: 992px) {
  .nd-sub-button a {
    height: 52px;
    line-height: 44px;
    font-size: 18px;
  }
}

@media (min-width: 992px) {
  #nd-Subscription-1 p {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  #nd-Subscription-1 .row .col-12 h1 {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .navbar-nav li:last-child {
    display: none;
  }
}

#nd-content-thr .row {
  width: 100%;
  margin: 0 auto;
}

#nd-team-img img {
  margin-bottom: 10px;
  max-width: 100%;
  height: 100px;
  border-radius: 12px;
  border: 2px solid #ebebeb;
}

#nd-team-img.col-4 {
  padding: 10px 0;
  text-align: center;
}

#nd-founder-head h1 {
  font-size: 18px;
  text-align: center;
  margin: 10px 0 0 0;
}

#nd-founder-content.col-8 {
  padding: 10px 0;
}

#nd-founder-content p {
  font-size: 11px;
  margin-bottom: 0;
}

#nd-founder-content a {
  color: #008DD2;
  font-size: 12px;
  margin: 0;
  padding: 0;
}

#nd-social-icon ul {
  margin: 0;
  padding: 0;
}

#nd-social-icon ul li {
  display: inline-block;
  margin-right: 5px;
  margin-top: 0;
}

#nd-social-icon ul li a i {
  font-size: 25px;
}

.fa.fa-instagram {
  color: transparent;
  background: -webkit-radial-gradient(30% 107%, circle, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background: -o-radial-gradient(30% 107%, circle, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background: -webkit-radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background-clip: text;
  -webkit-background-clip: text;
}

@media (min-width: 992px) {
  #nd-founder-head h1 {
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  #nd-founder-content p {
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  #nd-founder-content a {
    font-size: 14px;
  }
}

#nd-testimonials.col-12 {
  padding: 0;
  background: #D9DADA;
}

#nd-testimonials h1 {
  text-align: center;
  font-size: 18px;
  color: #EF7F1A;
  text-decoration: underline 2px;
  margin: 10px 0 0 0;
}

#nd-testimonials p {
  text-align: center;
  font-size: 16px;
  margin-bottom: 0;
  padding: 0 0 5px;
}

.nd-testimonial-div {
  width: 85%;
  height: auto;
  background: #FFF;
  margin: 0 auto;
  padding: 60px 15px 15px 15px;
  margin-bottom: 30px;
  margin-top: 60px;
}

.nd-testimonial-div p {
  font-size: 14px !important;
  text-align: left !important;
}

.nd-testimonial-div h1 {
  font-size: 14px !important;
  text-decoration: none !important;
  margin: 0 !important;
  text-align: left !important;
}

.nd-testimonial-div h1 span {
  color: #000;
  font-size: 12px;
}

#nd-contact-div h1 {
  text-align: center;
  font-size: 18px;
  color: #EF7F1A;
  text-decoration: underline 2px;
  margin: 10px 0 0 0;
}

.nd-contact-div img {
  width: 150px !important;
  height: auto;
  margin: 0 auto;
}

.nd-testimonial-img-div {
  text-align: center;
  margin-top: -120px;
}

#nd-contact-sec h1 {
  text-align: center;
  font-size: 18px;
  color: #EF7F1A;
  text-decoration: underline 2px;
  margin: 20px 0 10px 0;
}

#nd-contact-sec .row {
  width: 100%;
  margin: 0 auto;
}

#nd-contact-sec a {
  font-size: 14px;
  color: #2B2A29;
}

#nd-contact-sec span {
  color: #2B2A29;
  font-size: 14px;
}

#nd-social-icon-1 ul {
  margin: 0;
  padding: 0;
  display: inline;
}

#nd-social-icon-1 ul li {
  display: inline-block;
  margin-right: 5px;
  margin-top: 0;
}

#nd-social-icon-1 ul li a i {
  font-size: 25px;
}

@media (min-width: 768px) {
  #nd-social-icon-1 {
    text-align: center;
  }
}

#nd-footer-mobile .row {
  width: 100%;
  margin: 0 auto;
}

#nd-footer-mobile .row .col-4, #nd-footer-mobile .row .col-8 {
  padding: 0 5px;
}

.nd-disclaimer-cnt-1 h1 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .nd-disclaimer-cnt h1 {
    font-size: 15px !important;
  }
}

.nd-disclaimer-cnt h1 {
  font-size: 12px;
  margin-bottom: 0;
}

#nd-footer-mobile {
  padding-top: 30px;
  padding-bottom: 10px;
}

.nd-disclaimer-cnt-1 p {
  font-size: 12px;
  font-weight: 600;
}

@media (min-width: 992px) {
  .nd-disclaimer-cnt p {
    font-size: 13px !important;
    padding-right: 40px;
  }
}

.nd-disclaimer-cnt p {
  font-size: 12px;
  margin-bottom: 7px;
}

#nd-footer-logo {
  text-align: center;
}

#nd-foo-logo-txt-1 {
  text-align: center;
}

#nd-foo-logo-txt-1 h1 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
  text-decoration: underline 1px;
  -webkit-text-underline-position: under;
  -ms-text-underline-position: below;
  text-underline-position: under;
}

#nd-foo-logo-txt h1 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  text-decoration: underline 1px;
  -webkit-text-underline-position: under;
  -ms-text-underline-position: below;
  text-underline-position: under;
}

#nd-foo-logo-txt-1 p {
  padding: 0;
  margin: 0;
  font-size: 12px;
  font-weight: 600;
}

#nd-foo-logo-txt p {
  padding: 0;
  margin: 0;
  font-size: 10px;
  font-weight: 600;
}

#nd-foo-logo-txt h1 span {
  font-weight: 500;
}



@media (min-width: 992px) {
  nd-footer-desktop {
    display: none;
  }
}

@media (max-width: 991px) {
  #nd-footer-desktop {
    display: none;
  }
}

#nd-footer-logo-1 {
  text-align: center;
}

#nd-footer-desktop {
  padding: 20px 0;
}

#nd-footer-desktop .row {
  width: 100%;
  margin: 0 auto;
}

#fa-pay-1 {
  position: absolute;
  top: 48%;
  /* left: 46%; */
  font-size: 32px;
  color: #FFF;
  cursor: pointer;
}

#fa-pay-2 {
  position: absolute;
  top: 43%;
  /* left: 47%; */
  font-size: 32px;
  color: #FFF;
  cursor: pointer;
}

@media (min-width: 768px) {
  #nd-hme-content {
    margin-top: -100px;
  }
}

#nd-right-div-1 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.nd-static-content {
  text-align: center;
}

.nd-login-home {
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  display: none;
}

@media (min-width: 768px) {
  .nd-static-content {
    margin-top: -100px;
    padding-bottom: 70px;
  }
}

.nd-static-content-1 lu li {
  margin-bottom: 1em;
}

.nd-static-content-1 ul li {
  margin-bottom: 1em;
}

.nd-rpay-links a {
  text-decoration: none;
  cursor: pointer;
  font-size: 12px;
  color: #252525;
}

.nd-rpay-links {
  font-size: 14px !important;
}

#nd-login-link {
  padding-left: 19%;
}

@media (min-width: 414px) {
  #nd-login-link {
    padding-left: 26% !important;
  }
}

@media (min-width: 370px) {
  #nd-login-link {
    padding-left: 20%;
  }
}

#nd-myModal12 .close {
  float: right;
  font-size: 25px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: none;
  opacity: 1;
  position: absolute;
  right: 28px;
  top: 28px;
  z-index: 9;
  border: 2px solid #000;
  padding: 0;
  border-radius: 50px;
  width: 35px;
  height: 35px;
}

#nd-myModal12 .nd-logo-section p {
  width: 77%;
  padding: 7px 7px;
  border: 2px solid #000;
  font-size: 15px;
  margin: 12px 15px;
  text-align: center;
}

.faq-popup .modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0;
  border-bottom: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

#nd-myModal12 .modal-body {
  padding: 0px 0px 0px;
  background: linear-gradient(to bottom, #58c7e1 50%, #b0b1b2 50%);
}

#nd-myModal12 .nd-logo-section a img {
  width: 100%;
}

@media (min-width: 576px) {
  .nd-ratios {
    margin-top: -350px;
  }
}

@media (min-width: 360px) and (max-width: 575px) {
  .nd-ratios {
    margin-top: -250px;
  }
}

.nd-ratios {
  width: 100%;
  padding: 15px;
  z-index: 99999;
  position: relative;
  background: linear-gradient( 180deg,transparent 50%,#b0b1b2 0);
}

.nd-ratio1 {
  background: #fff;
  width: 100%;
  display: inline-block;
  border: 2px solid #f07e01;
  border-radius: 30px;
  height: 100%;
  text-align: center;
  padding: 6px 0 0;
}

.nd-ratio1 h1 {
  font-size: 15px;
  font-weight: 700;
}

.nd-ratio1 ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 90%;
  margin: 0 auto;
  padding: 0;
}

.nd-ratio1 ul li {
  list-style: none;
  font-weight: 500;
}

.nd-ratio1 li {
  width: 40%;
  font-weight: 700;
  line-height: 11px;
  align-items: center;
  padding: 5px 0;
  font-size: 11px;
  margin: 0 10px 10px;
  display: flex;
  justify-content: center;
}

.nd-ratio1 li:first-child {
  background: #63bbe1;
}

.nd-ratios li:nth-child(2), .nd-ratios li:nth-child(7) {
  background: #a0d6ee;
}

.nd-ratios li:nth-child(3), .nd-ratios li:nth-child(6) {
  background: #0092cb;
}

.nd-ratios li:nth-child(4), .nd-ratios li:nth-child(5) {
  background: #1f77a7;
}

.nd-ratio1.nd-ratio2 {
  height: auto;
  margin-top: 4%;
  border: 1px solid #fff;
  background: #f07e01;
}

.nd-ratio1.nd-ratio2 li {
  margin: 0 5px;
  width: 19%;
  background: #fff!important;
}

.nd-ratio1.nd-ratio2 ul {
  margin: 5px auto;
  padding: 0 5px;
}

.nd-ratio1.nd-ratio3 {
  height: 100%;
  margin-top: 4%;
  background: url(../../../assets/images/04.png);
  background-size: contain;
  position: relative;
  overflow: hidden;
}

.nd-bth {
  text-decoration: underline;
  margin-top: 0;
  color: #0f1012;
  cursor: pointer;
}

.nd-bth:hover {
  color: #0f1012 !important;
}

.nd-faq-popup .close {
  float: right;
  font-size: 25px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: none;
  opacity: 1;
  position: absolute;
  right: 40px;
  top: 28px;
  z-index: 9;
  border: 2px solid #000;
  padding: 0!important;
  border-radius: 50px;
  width: 35px;
  height: 35px;
}

.nd-faq-popup .modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0;
  border-bottom: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

#nd-myModal2 .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 45px 25px;
}

#nd-myModal2 .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 45px 25px;
}

#nd-myModal2 .faq-popup .model-body {
  position: relative;
  flex: 1 1 auto;
  padding: 45px 25px;
}

#nd-myModal2 .faq-popup .model-body {
  position: relative;
  flex: 1 1 auto;
  padding: 45px 25px;
}

#nd-myModal2 .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 45px 25px;
}

#nd-myModal2 .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 45px 25px;
}

#nd-myModal2 .modal-body:after {
  position: absolute;
  content: "";
  bottom: 0;
  background: url(../../../assets/images/faq-bg.png) no-repeat scroll 0 0;
  background-size: cover;
  background-position: bottom;
  width: 200px;
  height: 320px;
  left: 15px;
  z-index: 1;
}

.nd-faq-popup .modal-body h3 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 20px;
  text-align: left;
  position: relative;
  padding-bottom: 15px;
  display: table-cell;
  font-weight: 400;
}

.nd-faq-popup .modal-body h3 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 20px;
  text-align: left;
  position: relative;
  padding-bottom: 15px;
  display: table-cell;
  font-weight: 400;
}

.nd-faq-popup .modal-body h3 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 20px;
  text-align: left;
  position: relative;
  padding-bottom: 15px;
  display: table-cell;
  font-weight: 400;
}

.nd-faq-popup .modal-content {
  border-radius: 50px;
  text-align: center;
  top: 0;
  height: 90vh;
  background: #f48120;
  position: relative;
}

.nd-faq-popup .modal-content h3:after {
  background: #fff;
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 90px;
  height: 10px;
}

.nd-faq-popup p {
  color: #000;
  text-align: left;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px!important;
}

.nd-faq-popup .modal-content:after {
  position: absolute;
  content: "";
  bottom: 0;
  background: url(../../../assets/images/faq-bg.png) no-repeat scroll 0 0;
  background-size: cover;
  background-position: bottom;
  width: 200px;
  height: 320px;
  left: 15px;
  z-index: 1;
}

.nd-hmdtlsbold {
  font-weight: bold !important;
}

.nd-faq-popup .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 45px 25px;
}

#nd-summit-content .col-4 h1 {
  font-size: 16px;
  color: #EF7F1A;
  text-decoration: underline;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .nav-link.active {
    color: #fff !important;
  }
}

@media (max-width: 767px) {
  #nd-contct-us {
    margin-top: 79px;
  }
}

#nd-contct-us #nd-hme-mobile h1 {
  font-size: 30px;
  border-bottom: 1px solid #f58220;
  padding-bottom: 5px;
  margin-bottom: 10px;
  display: inline-block;
  text-align: left;
  display: block;
  color: #f58220;
  padding-top: 10px;
}

#nd-contct-us #nd-hme-desktop h1 {
  font-size: 30px;
  border-bottom: 1px solid #f58220;
  padding-bottom: 5px;
  margin-bottom: 10px;
  display: inline-block;
  text-align: left;
  display: block;
  color: #f58220;
  padding-top: 90px;
}

#nd-contact-us #nd-hme-mobile h1 {
  font-size: 30px;
  border-bottom: 1px solid #f58220;
  padding-bottom: 5px;
  margin-bottom: 10px;
  display: inline-block;
  text-align: left;
  display: block;
  color: #f58220;
  padding-top: 15px;
}

@media (min-width: 768px) {
  #nd-contct-us {
    background: url("../../../assets/images/mountain.jpg") no-repeat scroll 0 0;
    height: 600px;
    background-size: cover;
  }
}

@media (max-width: 767px) {
  #nd-contct-us {
    padding: 10px 0 50px;
    background: url("../../../assets/images/last_page.png") no-repeat scroll 0 0;
    background-size: cover;
  }
}

.nd-about-dtls {
  font-size: 16px;
  color: #000;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
}

#nd-contct-us #nd-hme-desktop p {
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  line-height: 20px;
}

#nd-contct-us #nd-hme-mobile p {
  font-size: 12px;
  color: #fff;
  font-weight: 500;
  line-height: 20px;
}

.nd-call-action h3 {
  color: #f58220;
  margin-bottom: 5px;
  font-size: 28px;
}

.nd-call-action a {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.nd-call-action {
  text-align: left;
  margin-top: 25px;
}

.nd-call-action span {
  font-size: 16px;
  color: #fff;
  display: inline;
}

.nd-call-action ul {
  margin: 0;
  padding: 0;
  display: inline;
}

.nd-call-action ul li {
  margin-right: 10px;
  list-style: none;
  font-weight: 500;
  display: inline-block;
  margin-right: 5px;
  margin-top: 0;
}

.nd-call-action ul li a i {
  font-size: 25px;
}

#nd-hme-mobile h1 span {
  font-weight: 700;
}

#nd-hme-desktop h1 span {
  font-weight: 700;
}

@media (min-width: 992px) {
  .nd-address-fields-div {
    display: flex;
    justify-content: space-between;
    width: 60%;
  }
}

@media (max-width: 991px) {
  .nd-address-fields-div {
    display: flex;
    justify-content: space-between;
    width: 80%;
  }
}

.nd-address-fields-div li {
  display: inline-block;
  width: auto;
  padding: 0;
  position: relative;
}

.nd-pad-div.nd-add {
  padding: 0 75px;
}

#nd-contct-us #nd-hme-desktop:after {
  background: url("../../../assets/images/moon-1.png") no-repeat scroll 0 0;
  position: absolute;
  content: "";
  width: 132px;
  height: 117px;
  left: auto;
  right: 0;
  top: 60px;
  z-index: 1;
}

.nd-about-head {
  font-size: 30px;
  border-bottom: 1px solid #000;
  padding-bottom: 5px;
  margin-bottom: 10px;
  display: inline-block;
  text-align: left;
  display: block;
  color: #000;
  padding-top: 90px;
}

#nd-about-us {
  background: #D9DADA;
  padding-bottom: 20px;
}

.nd-about-head-dview {
  font-size: 30px;
  border-bottom: 1px solid #000;
  padding-bottom: 5px;
  margin-bottom: 10px;
  text-align: left;
  display: block;
  color: #000;
  padding-top: 50px;
  margin-left: 75px;
}

.nd-vission-div {
  background: url(../../../assets/images/our_team.png) no-repeat scroll 0 0;
  background-size: cover;
  border: 4px solid #e78235;
  border-radius: 25px;
  margin: 10px 0;
}

.nd-vission-div h3 {
  color: #fff;
  font-size: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 0;
  text-align: left;
  padding-left: 15px;
  line-height: 1;
}

.nd-vission-div p {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

#nd-hme-desktop .nd-vission-div {
  background: url(../../../assets/images/team1.jpg) no-repeat scroll 0 0;
  background-position: bottom;
  background-size: cover;
  padding: 65px 75px;
  border: 2px solid #e78235;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  position: relative;
  z-index: 9;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  margin: 0 -15px 30px;
}

#nd-hme-desktop .nd-vission-div:after {
  background: url(../../../assets/images/moon-1.png) no-repeat scroll 0 0;
  position: absolute;
  content: "";
  width: 132px;
  height: 117px;
  left: auto;
  right: 25px;
  top: 50%;
  z-index: -1;
  margin-top: -85px;
}

#nd-hme-desktop .nd-vission-div h3 {
  color: #e78235;
  font-size: 35px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 10px;
  text-align: left;
  padding-left: 0;
}

#nd-hme-desktop .nd-vission-div p {
  color: #fff;
  font-size: 18px;
  line-height: 30px;
  text-align: left;
}

.nd-faq-head {
  text-align: left;
  display: block;
  padding-top: 25px;
  border-bottom: 1px solid #000;
  padding-bottom: 5px;
  margin-bottom: 10px;
  font-size: 30px;
}

.nd-faq-dtls {
  padding: 0;
  font-size: 16px;
  text-align: center;
  line-height: 1.5;
}

@media (max-width: 767px) {
  #nd-your-faq {
    background: url(../../../assets/images/our_product_bg.png) no-repeat scroll 0 0;
    background-size: cover;
    background-position: 100% 100%;
  }
}

#nd-your-faq:after {
  position: absolute;
  content: "";
  bottom: 0;
  background: url(../../../assets/images/faq_012.png) no-repeat scroll 0 0;
  background-size: cover;
  width: 228px;
  height: 367px;
  left: 0;
  z-index: 0;
  box-sizing: border-box;
  margin-left: 10px;
}

#nd-your-faq {
  position: relative;
  z-index: 9;
}

.nd-faq-content-st h3 a {
  color: #000;
  font-size: 16px;
  font-weight: 500;
}

.nd-faq-content-st h3:after {
  background: #4e4e4e;
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 90px;
  height: 10px;
}

.nd-faq-content-st h3 {
  word-break: break-word;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  padding-bottom: 15px;
  text-align: left;
  position: relative;
}

.nd-faq-content-st {
  background: #fff;
  border: 2px solid #e78235;
  padding: 9px;
  min-height: 132px;
  margin-bottom: 20px;
  position: relative;
  align-items: center;
  display: flex;
  width: 48%;
}

.nd-faq-content-tt {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.nd-faq-qt {
  padding-left: 70px;
}

.nd-faq-stt .owl-theme .owl-nav [class*=owl-] {
  background: #9bbc2a;
  width: 25px;
  height: 30px;
  color: #fff;
}

#nd-your-faq .owl-carousel .owl-nav.disabled {
  display: block;
}

#nd-your-faq button.owl-prev i {
  transform: rotate( 90deg);
}

#nd-your-faq button.owl-prev i {
  transform: rotate( 90deg);
}

#nd-your-faq button.owl-next i {
  transform: rotate( 270deg);
}

#nd-your-faq .owl-nav {
  position: absolute;
  right: 10px;
  bottom: -44px;
}

#nd-myModal-4 ol {
  list-style-type: lower-alpha;
  text-align: left;
  color: #000;
}

.nd-faqimgdiv {
  margin-left: -15px;
  margin-right: -15px;
}

.nd-faqimgdiv img {
  width: 100%;
}

#nd-your-faq #nd-hme-mobile {
  margin-top: 78px;
  padding-bottom: 60px;
}

.nd-faq-head-dview {
  position: absolute;
  top: 50px;
  border-bottom: 1px solid #000;
  width: 85%;
  margin: 0 75px;
}

#nd-your-faq #nd-hme-desktop .row {
  margin-top: -65px;
  padding-bottom: 100px;
}

#nd_faq_slider-1 .nd-faq-content-st {
  width: 80%;
  min-height: 160px;
}

#nd_faq_slider-1 button.owl-prev i {
  transform: rotate( -90deg);
}

.nd-abt-img img {
  width: 150px;
  height: auto;
}

.nd-abt-img {
  text-align: center;
  margin-bottom: 15px;
}

#nd-hme-desktop .contact-clean {
  padding: 30px 0;
}

#nd-hme-desktop .contact-clean form {
  width: 95% !important;
  max-width: 95%;
}

.contact-clean form textarea.form-control {
  min-height: 158px;
}

